import React, { useState, useEffect, useMemo } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import { Fx, Equity, Crypto } from "../AssetPermissions";
import { useGetIbPermissionsQuery } from "../../../redux/brokerApi";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import {
  useAssetPermissionMutation,
  useGetAssetPairsQuery,
} from "../../../redux/usersApi";

const AssetTabs = ({ editData, isEdit }) => {
  const [assetData, setAssetData] = useState(null);
  const [updateAssetPermissions, { isLoading: updatingAssets }] =
    useAssetPermissionMutation();

  const selectChange = (data) => {
    setAssetData(data);
  };

  const handlePermissionsTabsSubmit = async () => {
    const values = assetData.selectedPairs.map((coinId) => coinId.value);
    console.log({
      coinIds: values,
      userId: editData.id,
      type: assetData.type,
    });

    // return;

    const result = await updateAssetPermissions({
      coinIds: values,
      userId: editData.id,
      type: assetData.type,
    });
    toast.dismiss();
    if (result?.data?.status) {
      toast.success(result.data.message);
      assetData.fn();
    } else {
      toast.error(result?.data?.message || "Error occurred");
    }
  };
  return (
    <Tabs>
      <TabList>
        <Tab>Crypto Pairs</Tab>
        <Tab>Fx Pairs</Tab>
        <Tab disabled>Equity</Tab>
      </TabList>

      <TabPanel>
        <Crypto editData={editData} selectChange={selectChange} />
      </TabPanel>
      <TabPanel>
        <Fx editData={editData} selectChange={selectChange} />
      </TabPanel>
      <TabPanel>
        <Equity editData={editData} selectChange={selectChange} />
      </TabPanel>
      <Button
        className="btn btn1 mt-2"
        variant={"primary"}
        isLoading={updatingAssets}
        onClick={handlePermissionsTabsSubmit}
      >
        Save
      </Button>
    </Tabs>
  );
};

export default AssetTabs;
