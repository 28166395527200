import React, { useEffect, useState } from "react";
import {
  useGetAssetCommissionQuery,
  useAddAssetCommissionMutation,
  useUpdateAssetCommissionMutation,
} from "../../../redux/commissionApi";
import TableLoader from "../../widgets/TableLoader";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import {
  ValidateInputFields,
  isNegative,
} from "../../validations/ValidateInputFields";

const defaultState = {
  commissionRate: { value: "", required: true },
  commissionType: { value: "", required: true },
  sectionFee: { value: "", required: false },
  tafFee: { value: "", required: false },
  nsccFee: { value: "", required: false },
  clrFee: { value: "", required: false },
  nsdqFee: { value: "", required: false },
  ecnFee: { value: "", required: false },
  overNightFee: { value: "", required: false },
};
const defaultErrorState = {
  commissionRate: "",
  commissionType: "",
  sectionFee: "",
  tafFee: "",
  nsccFee: "",
  clrFee: "",
  nsdqFee: "",
  ecnFee: "",
  overNightFee: "",
};

const StockCommission = ({ targetUserId, isEdit, isNew, refetch, onClose }) => {
  const [stockCommission, setStockCommission] = useState(defaultState);
  const [stockCommissionError, setStockCommissionError] =
    useState(defaultErrorState);
  const [addAssetCommission, { isLoading: addingAssetCommission }] =
    useAddAssetCommissionMutation();
  const [updateAssetCommission, { isLoading: updatingAssetCommission }] =
    useUpdateAssetCommissionMutation();

  const {
    data,
    isLoading,
    refetch: refetchAssetCommissions,
  } = useGetAssetCommissionQuery({
    targetUserId,
    assetType: "stock",
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const updatedStockRiskInfo = Object.keys(defaultState).reduce(
        (acc, key) => {
          acc[key] = {
            ...defaultState[key],
            value: `${
              data[0][key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()]
            }`,
          };
          return acc;
        },
        {}
      );
      setStockCommission(updatedStockRiskInfo);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStockCommission((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    let validationError = ValidateInputFields(
      name,
      value,
      stockCommission[name].required
    );
    if (validationError) {
      setStockCommissionError((prevError) => {
        return { ...prevError, [name]: validationError };
      });

      return;
    }

    validationError = isNegative(value);

    setStockCommissionError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(stockCommission).map(([key, { value }]) => [key, value])
      );

      for (let key in stockCommission) {
        let validationError = ValidateInputFields(
          key,
          stockCommission[key].value,
          stockCommission[key].required
        );
        setStockCommissionError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
        validationError = isNegative(stockCommission[key].value);

        if (validationError) return;
      }

      const body = { targetUserId, assetType: "stock", ...values };

      const mutationResult =
        data && data.length > 0
          ? await updateAssetCommission(body)
          : await addAssetCommission(body);
      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        // onClose();
        refetchAssetCommissions();
      }
    };

    handleUpdateOrAdd();
  };

  return (
    <div>
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">Commission Rate*</label>
              <input
                type="number"
                className="form-control"
                placeholder={`Enter Rate`}
                name="commissionRate"
                onChange={handleChange}
                value={stockCommission.commissionRate?.value}
              />
              <span style={{ color: "red" }}>
                {stockCommissionError.commissionRate}
              </span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">Commission Type*</label>
              <select
                className="form-control"
                name="commissionType"
                onChange={handleChange}
                value={stockCommission.commissionType?.value}
              >
                <option value="">select Type</option>
                <option value="perShare">Per Share</option>
              </select>

              <span style={{ color: "red" }}>
                {stockCommissionError.commissionType}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">Section31 Fee</label>
              <input
                type="number"
                className="form-control"
                placeholder={`Enter Fee`}
                name="sectionFee"
                onChange={handleChange}
                value={stockCommission.sectionFee?.value}
              />
              <span style={{ color: "red" }}>
                {stockCommissionError.sectionFee}
              </span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">Taf Fee</label>
              <input
                type="number"
                className="form-control"
                placeholder={`Enter Fee`}
                name="tafFee"
                onChange={handleChange}
                value={stockCommission.tafFee?.value}
              />
              <span style={{ color: "red" }}>
                {stockCommissionError.tafFee}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">Nscc Fee</label>
              <input
                type="number"
                className="form-control"
                placeholder={`Enter Fee`}
                name="nsccFee"
                onChange={handleChange}
                value={stockCommission.nsccFee?.value}
              />
              <span style={{ color: "red" }}>
                {stockCommissionError.nsccFee}
              </span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">Clr Fee</label>
              <input
                type="number"
                className="form-control"
                placeholder={`Enter Fee`}
                name="clrFee"
                onChange={handleChange}
                value={stockCommission.clrFee?.value}
              />
              <span style={{ color: "red" }}>
                {stockCommissionError.clrFee}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">Nsdq Fee</label>
              <input
                type="number"
                className="form-control"
                placeholder={`Enter Fee`}
                name="nsdqFee"
                onChange={handleChange}
                value={stockCommission.nsdqFee?.value}
              />
              <span style={{ color: "red" }}>
                {stockCommissionError.nsdqFee}
              </span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">Ecn Fee</label>
              <input
                type="number"
                className="form-control"
                placeholder={`Enter Fee`}
                name="ecnFee"
                onChange={handleChange}
                value={stockCommission.ecnFee?.value}
              />
              <span style={{ color: "red" }}>
                {stockCommissionError.ecnFee}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">Overnight Fee</label>
              <input
                type="number"
                className="form-control"
                placeholder={`Enter Fee`}
                name="overNightFee"
                onChange={handleChange}
                value={stockCommission.overNightFee?.value}
              />
              <span style={{ color: "red" }}>
                {stockCommissionError.overNightFee}
              </span>
            </div>
          </div>
          <Button
            className="btn btn1"
            variant={"primary"}
            isLoading={updatingAssetCommission || addingAssetCommission}
            onClick={handleSubmit}
          >
            {data && data.length > 0 ? "Update" : "Submit"}
          </Button>
        </>
      )}
    </div>
  );
};

export default StockCommission;
