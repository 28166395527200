import React, { useState } from "react";
import { useGetAllRequestsQuery } from "../../../redux/supportApi";
import TableLoader from "../../widgets/TableLoader";
import SupportTable from "./SupportTable";

const Support = () => {
  const { data: records, isLoading, isError } = useGetAllRequestsQuery();

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Supports</h3>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <SupportTable data={records} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Support;
