import React, { useEffect, useState } from "react";
import {
  ValidateInputFields,
  isNegative,
} from "../../validations/ValidateInputFields";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import {
  useAddRiskInfoMutation,
  useGetRiskInfoQuery,
  useUpdateRiskInfoMutation,
} from "../../../redux/riskInfoApi";
import TableLoader from "../../widgets/TableLoader";

const defaultState = {
  maxLossPerDay: { value: "", required: true, label: "Max Loss Per Day" },
  balanceAutoClose: {
    value: "",
    required: true,
    label: "Crypto Balance Auto Close",
  },
};

const defaultErrorState = {
  maxLossPerDay: "",
  balanceAutoClose: "",
};

const CryptoRiskInfo = ({ targetUserId, isEdit, isNew, refetch, onClose }) => {
  const [cryptoRiskInfo, setCryptoRiskInfo] = useState(defaultState);
  const [cryptoRiskInfoError, setCryptoRiskInfoError] =
    useState(defaultErrorState);
  const [addRiskInfo, { isLoading: addingRiskInfo }] = useAddRiskInfoMutation();
  const [updateRiskInfo, { isLoading: updatingRiskInfo }] =
    useUpdateRiskInfoMutation();
  const {
    data,
    isLoading,
    refetch: refetchRiskInfo,
  } = useGetRiskInfoQuery({
    targetUserId,
    riskInfoType: "crypto",
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const updatedStockRiskInfo = Object.keys(defaultState).reduce(
        (acc, key) => {
          acc[key] = {
            ...defaultState[key],
            value: `${
              data[0][key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()]
            }`,
          };
          return acc;
        },
        {}
      );
      setCryptoRiskInfo(updatedStockRiskInfo);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCryptoRiskInfo((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    let validationError = ValidateInputFields(
      name,
      value,
      cryptoRiskInfo[name].required
    );
    if (validationError) {
      setCryptoRiskInfoError((prevError) => {
        return { ...prevError, [name]: validationError };
      });
      return;
    }

    validationError = isNegative(value);
    setCryptoRiskInfoError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(cryptoRiskInfo).map(([key, { value }]) => [key, value])
      );

      for (let key in cryptoRiskInfo) {
        let validationError = ValidateInputFields(
          key,
          cryptoRiskInfo[key].value,
          cryptoRiskInfo[key].required
        );
        setCryptoRiskInfoError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
        validationError = isNegative(cryptoRiskInfo[key].value);
        if (validationError) return;
      }

      const body = { targetUserId, riskInfoType: "crypto", ...values };

      const mutationResult =
        data && data.length > 0
          ? await updateRiskInfo(body)
          : await addRiskInfo(body);
      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        // onClose();
        refetchRiskInfo();
      }
    };

    handleUpdateOrAdd();
  };

  if (isLoading) {
    return <TableLoader />;
  }

  return (
    <form>
      <div className="row">
        {Object.keys(defaultState).map((key) => (
          <div key={key} className="form-group col-md-6">
            <label className="fw-bold">
              {cryptoRiskInfo[key].label}
              {cryptoRiskInfo[key].required ? "*" : ""}
            </label>
            <input
              type="number"
              className="form-control"
              placeholder={`Enter ${cryptoRiskInfo[key].label}`}
              name={key}
              onChange={handleChange}
              value={cryptoRiskInfo[key].value}
            />
            <span style={{ color: "red" }}>{cryptoRiskInfoError[key]}</span>
          </div>
        ))}
      </div>
      <Button
        className="btn btn1"
        variant={"primary"}
        isLoading={addingRiskInfo || updatingRiskInfo}
        onClick={handleSubmit}
      >
        {data && data.length > 0 ? "Update" : "Submit"}
      </Button>
    </form>
  );
};

export default CryptoRiskInfo;
