import toast from "react-hot-toast";

export const handleToast = (result) => {
  toast.dismiss();

  if (result?.error?.status === 403) {
    toast.error(result?.error.data?.message);
    return;
  }

  if (result?.data?.status) {
    toast.success(result.data.message);
  } else {
    toast.error(result?.data?.message || "Error occurred");
  }
};
