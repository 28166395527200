import React, { useState, useEffect } from "react";
import { useGetBuyOrdersQuery } from "../../../redux/ReportsApi";
import TableLoader from "../../widgets/TableLoader";
import { BuyOrdersTable } from "./BuyOrderTable";

const BuyOrders = () => {
  const [isActiveA, setIsActiveA] = useState(true);
  const [isActiveB, setIsActiveB] = useState(false);
  const [records, setRecords] = useState([]);
  const { data, isLoading } = useGetBuyOrdersQuery();

  useEffect(() => {
    if (data && data.length > 0) {
      const filter = data.filter((order) => order.is_vendor === "1");
      setRecords(filter);
    }
  }, [data]);

  const toggleButton = (button) => {
    if (button === "1") {
      const filter = data.filter((order) => order.is_vendor === "1");
      setRecords(filter);
      setIsActiveA(true);
      setIsActiveB(false);
    } else if (button === "0") {
      const filter = data.filter((order) => order.is_vendor === "0");
      setRecords(filter);
      setIsActiveA(false);
      setIsActiveB(true);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Buy Orders</h3>
            <div className=" ms-auto"></div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="row">
                <div className="col-6">
                  <button
                    className={`btn  m-2 ${
                      isActiveA ? "btn-secondary text-white" : "btn1"
                    }`}
                    onClick={() => toggleButton("1")}
                  >
                    A Book
                  </button>
                  <button
                    className={`btn ${
                      isActiveB ? "btn-secondary text-white" : "btn1"
                    }`}
                    onClick={() => toggleButton("0")}
                  >
                    B Book
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <BuyOrdersTable data={records} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyOrders;
