import React, { useState, useEffect, useRef } from "react";
import { HeadWrapper } from "../Elements";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { socketUrl } from "../../constant/BaseUrl";
import socketIOClient from "socket.io-client";
import { BuyOrdersTable } from "./BuyOrderTable";
import { SellOrdersTable } from "./SellOrderTable";
import { useDeleteOrderMutation } from "../../../redux/exchange";
import { handleToast } from "../../utils/handleToast";

const LiveOrders = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [buyOrders, setBuyOrders] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);
  const localSocket = useRef(null);
  const [deleteOrder] = useDeleteOrderMutation();

  useEffect(() => {
    if (!localSocket.current) {
      localSocket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });
      localSocket.current.on("connect", () => {
        console.log("Socket is connected");
        handleSocketMessages(localSocket);
      });
      localSocket.current.emit("ping");
      localSocket.current.emit("getAdminOrders");
    }
    return () => {
      if (
        localSocket.current &&
        !window.location.pathname.includes("/live-orders")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        localSocket.current.disconnect();
      }
    };
  }, []);

  const handleSocketMessages = (localSocket) => {
    localSocket.current.on("pong", () => {
      console.log("Received PONG from server");
    });

    localSocket.current.on(
      "resultAdminOrders",
      ({ buyOrderData, sellOrderData }) => {
        setBuyOrders(buyOrderData.data);
        setSellOrders(sellOrderData.data);
      }
    );

    localSocket.current.off("refresh");
    localSocket.current.on("refresh", () => {
      localSocket.current.emit("ping");
      localSocket.current.emit("getAdminOrders");
      handleSocketMessages(localSocket);
    });
  };

  const handleTabs = (index) => {
    setTabIndex(index);
  };

  const handleAdminDeleteOrder = async (data) => {
    const { userId, orderType, orderId } = data;

    try {
      const mutationResult = await deleteOrder({ userId, orderType, orderId });
      handleToast(mutationResult);
    } catch (error) {}
  };

  return (
    <HeadWrapper title="Live Orders">
      <div className="white-box">
        <Tabs selectedIndex={tabIndex} onSelect={handleTabs}>
          <TabList>
            <Tab>BUY</Tab>
            <Tab>SELL</Tab>
          </TabList>

          <TabPanel>
            <BuyOrdersTable
              data={buyOrders}
              onDelete={handleAdminDeleteOrder}
            />
          </TabPanel>
          <TabPanel>
            <SellOrdersTable
              data={sellOrders}
              onDelete={handleAdminDeleteOrder}
            />
          </TabPanel>
        </Tabs>
      </div>
    </HeadWrapper>
  );
};

export default LiveOrders;
