"use client";
import * as React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "react-tabs/style/react-tabs.css";
import "react-phone-input-2/lib/style.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Login from "./components/auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./components/widgets/Dashboard";
import Crypto from "./components/pages/crypto/Crypto";
import CryptoPairs from "./components/pages/cryptopairs/CryptoPairs";
import CryptoCommission from "./components/pages/crypto/Commission";
import CryptoRisk from "./components/pages/crypto/Risk";

import Support from "./components/pages/support/Support";
import ChatRoom from "./components/pages/support/ChatRoom";
import ExchangeManagement from "./components/pages/exchangeManagement/Exchange";
import AccountBroker from "./components/pages/accountBroker/AccountBroker";
import Button from "./components/widgets/Button";
import TradeFee from "./components/pages/commission/TradeFee";
import AdminList from "./components/pages/admin/AdminList";
import BrokerList from "./components/pages/broker/BrokerList";
import IbList from "./components/pages/ib/IbList";
import SubIbList from "./components/pages/subIb/SubIbList";
import ChangePassword from "./components/pages/broker/ChangePassword";
import KycVendor from "./components/pages/vendorManagement/KycVendor";
import ClearingHouse from "./components/pages/vendorManagement/ClearingHouse";
import DataProvider from "./components/pages/vendorManagement/DataProvider";
import BuyOrders from "./components/pages/reports/BuyOrders";
import SellOrders from "./components/pages/reports/SellOrders";
import Spread from "./components/pages/spread/Spread";
import Notifications from "./components/pages/notifications/Notifications";
import LiveOrders from "./components/pages/LiveOrders/LiveOrders";
import Kyc from "./components/pages/kyc/Kyc";
import UrlManagement from "./components/pages/urlManagement/UrlManagement";

import FxCurrency from "./components/pages/fxManagement/Currency";
import FxCommission from "./components/pages/fxManagement/Commission";
import FxRisk from "./components/pages/fxManagement/Risk";

import EquityStocks from "./components/pages/equityManagement/Stocks";
import EquityCommission from "./components/pages/equityManagement/Commission";
import EquityRisk from "./components/pages/equityManagement/Risk";

//

import TransactionCrypto from "./components/pages/transactionReport/Crypto";
import TransactionFx from "./components/pages/transactionReport/Fx";
import TransactionEquity from "./components/pages/transactionReport/Equity";

import CommissionCrypto from "./components/pages/commissionReport/Crypto";
import CommissionFx from "./components/pages/commissionReport/Fx";
import CommissionEquity from "./components/pages/commissionReport/Equity";

import CashCrypto from "./components/pages/cashActivity/Crypto";
import CashFx from "./components/pages/cashActivity/Fx";
import CashEquity from "./components/pages/cashActivity/Equity";

import PositionFx from "./components/pages/positionManagement/Fx";
import PositionEquity from "./components/pages/positionManagement/Equity";

import MarginFxStatus from "./components/pages/marginTradeFx/Status";
import SupportStatus from "./components/pages/support/Status";
import BuyCollections from "./components/pages/commissionReport/CryptoCommissionsReports/BuyCollections";
import SellCollections from "./components/pages/commissionReport/CryptoCommissionsReports/SellCollections";
import WithdrawalCollections from "./components/pages/commissionReport/CryptoCommissionsReports/WithdrawalCollections";
import Leverage from "./components/pages/fxManagement/Leverage";
import BuyOrdersFx from "./components/pages/reports/BuyOrdersFx";
import SellOrdersFx from "./components/pages/reports/SellOrdersFx";
import LiveOrdersFx from "./components/pages/LiveOrders/LiveOrdersFx";
import SpreadFx from "./components/pages/fxManagement/Spread";
import TradeFeeFx from "./components/pages/fxManagement/TradeFee";
import PositionSummery from "./components/pages/fxManagement/PositionSummery";
import OrdersSummery from "./components/pages/fxManagement/OrdersSummery";
import LiveOrdersEq from "./components/pages/LiveOrders/LiveOrdersEq";
//transact
//commis
//cash
//position

// import "bootstrap/dist/css/bootstrap.min.css";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/login" element={<Login />} />
      <Route path="" element={<ProtectedRoute />}>
        <Route index={true} path="/dashboard" element={<Dashboard />} />
        <Route path="/brokers" element={<BrokerList />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/ibs" element={<IbList />} />
        <Route path="/subIbs" element={<SubIbList />} />
        <Route path="/admins" element={<AdminList />} />
        <Route path="/crypto" element={<Crypto />} />
        <Route path="/crypto-pairs" element={<CryptoPairs />} />
        <Route path="/exchange-management" element={<ExchangeManagement />} />
        <Route path="/broker-account" element={<AccountBroker />} />
        <Route path="/commission" element={<TradeFee />} />
        <Route path="/spread" element={<Spread />} />
        <Route path="/spread-fx" element={<SpreadFx />} />
        <Route path="/coins-commission" element={<CryptoCommission />} />
        <Route path="/coins-risk" element={<CryptoRisk />} />
        <Route path="/url-management" element={<UrlManagement />} />
        <Route path="/kyc-vendor" element={<KycVendor />} />
        <Route path="/clearing-house" element={<ClearingHouse />} />
        <Route path="/data-provider" element={<DataProvider />} />

        <Route path="/fx-currency" element={<FxCurrency />} />
        <Route path="/fx-commission" element={<TradeFeeFx />} />
        <Route path="/fx-risk" element={<FxRisk />} />
        <Route path="/leverage" element={<Leverage />} />
        <Route path="/position-summery" element={<PositionSummery />} />
        <Route path="/orders-summery" element={<OrdersSummery />} />

        <Route path="/equity-stocks" element={<EquityStocks />} />
        <Route path="/equity-commission" element={<EquityCommission />} />
        <Route path="/equity-risk" element={<EquityRisk />} />

        <Route path="/transaction-crypto" element={<TransactionCrypto />} />
        <Route path="/transaction-fx" element={<TransactionFx />} />
        <Route path="/transaction-equity" element={<TransactionEquity />} />

        <Route path="/commission-crypto" element={<CommissionCrypto />} />
        <Route
          path="/buy-commission-collections"
          element={<BuyCollections />}
        />
        <Route
          path="/sell-commission-collections"
          element={<SellCollections />}
        />
        <Route
          path="/withdrawals-commission-collections"
          element={<WithdrawalCollections />}
        />
        <Route path="/commission-fx" element={<CommissionFx />} />
        <Route path="/commission-equity" element={<CommissionEquity />} />

        <Route path="/cash-crypto" element={<CashCrypto />} />
        <Route path="/cash-fx" element={<CashFx />} />
        <Route path="/cash-equity" element={<CashEquity />} />

        <Route path="/position-fx" element={<PositionFx />} />
        <Route path="/position-equity" element={<PositionEquity />} />

        <Route path="/margin-status" element={<MarginFxStatus />} />
        <Route path="/support-status" element={<SupportStatus />} />

        <Route path="/buy-orders" element={<BuyOrders />} />
        <Route path="/sell-orders" element={<SellOrders />} />
        <Route path="/buy-orders-fx" element={<BuyOrdersFx />} />
        <Route path="/sell-orders-fx" element={<SellOrdersFx />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/live-orders" element={<LiveOrders />} />
        <Route path="/live-orders-fx" element={<LiveOrdersFx />} />
        <Route path="/live-orders-eq" element={<LiveOrdersEq />} />
        <Route path="/kyc/:type" element={<Kyc />} />
        <Route path="/support">
          <Route index={true} element={<Support />} />
          <Route index={false} path=":ticketId" element={<ChatRoom />} />
        </Route>
      </Route>
      <Route path="*" element={<Dashboard />} />
    </Route>
  )
);
const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </Button>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center">Loading...</div>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <RouterProvider router={router} />
        </ErrorBoundary>
      </React.Suspense>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
