import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useUserHistoryByTypeQuery } from "../../../redux/ReportsApi";
import {
  UserWalletTransTable,
  BuyOrdersTable,
  SellOrdersTable,
  BalancesTable,
} from "../Elements/";
import TableLoader from "../../widgets/TableLoader";
import { baseUrl } from "../../constant/BaseUrl";
const url = `${baseUrl.replace("/api-admin/v1", "")}`;

const tabObj = {
  0: "balance",
  1: "buy",
  2: "sell",
  3: "Deposit",
  4: "Withdrawal",
};

export const UserInfoTabs = ({ userId }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [type, setType] = useState("balance");
  const [balanceData, setBalanceData] = useState([]);

  const { data, isLoading, isFetching } = useUserHistoryByTypeQuery({
    userId,
    type,
  });

  const handleTabChange = (index) => {
    setTabIndex(index);
    setType(tabObj[index]);
    const typeApi = tabObj[index];
    console.log(index, "innddex", typeApi);
  };

  useEffect(() => {
    if (type === "balance" && data) {
      const balances = data.filter((value) => {
        const hasWalletAmount = value.getValue.main[0].coin_amount;
        const hasSpotAmount = value.getValue.spot[0].coin_amount;

        return hasWalletAmount || hasSpotAmount || 0;
      });
      setBalanceData(balances);
    }
  }, [type, data]);

  return (
    <div className="parent-tabs">
      <h3>Portfolio (USDT): 0.000</h3>
      <Tabs
        className="level-1-panels row"
        selectedIndex={tabIndex}
        onSelect={handleTabChange}
      >
        <TabList className="text-uppercase">
          <Tab>Balance</Tab>
          <Tab>Buy</Tab>
          <Tab>Sell</Tab>
          <Tab>Deposit</Tab>
          <Tab>Withdraw</Tab>
        </TabList>

        {isLoading || isFetching ? (
          <TableLoader />
        ) : (
          <>
            <TabPanel>
              <BalancesTable data={balanceData} />
            </TabPanel>
            <TabPanel>
              <BuyOrdersTable data={data} />
            </TabPanel>
            <TabPanel>
              <SellOrdersTable data={data} />
            </TabPanel>
            <TabPanel>
              <UserWalletTransTable data={data} />
            </TabPanel>
            <TabPanel>
              <UserWalletTransTable data={data} />
            </TabPanel>
          </>
        )}
      </Tabs>
    </div>
  );
};
