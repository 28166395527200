export const HeadWrapper = ({ children, title, button }) => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title text-capitalize">{title}</h3>
            <div className=" ms-auto">{button}</div>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};
