import React, { useState, useMemo } from "react";
import { useGetCryptoPairsQuery } from "../../../redux/cryptoPairsApi";
import {
  useGetExchangeCredentialsQuery,
  useUpdateExchangeCredentialsMutation,
} from "../../../redux/exchange";
import TableLoader from "../../widgets/TableLoader";
import ExchangeManagementTable from "./ExchangeTable";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import Select from "react-select";

const ExchangeManagement = () => {
  const {
    data: exchangeData,
    isLoading: exchangeLoading,
    refetch: refetchExInfo,
  } = useGetExchangeCredentialsQuery();
  const { data: records, isLoading, refetch } = useGetCryptoPairsQuery();
  const [updateExchangeCredentials, { isLoading: updatingExchange }] =
    useUpdateExchangeCredentialsMutation();
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedPairs, setSelectedPairs] = useState([]);
  const [currantPairs, setCurrantPairs] = useState([]);
  const [exOptions, setExOptions] = useState([]);

  const handleShow = () => setShow(true);

  const handleModalClose = () => {
    setCurrantPairs([]);
    setExOptions([]);
    setEditData(null);
    setShow(false);
    setSelectedPairs([]);
  };

  const handleEdit = (data) => {
    const getOptions = (pairs) => {
      return records.filter((coin) => {
        const coinPair = coin.first_coin_symbol + coin.second_coin_symbol;
        return !pairs.some((pair) => pair.name1 + pair.name2 === coinPair);
      });
    };

    const createKeyValuePair = (pairs) => {
      return pairs?.map((pair) => ({
        value: pair.pair_id,
        label: `${pair.name1?.toUpperCase()}/${pair.name2?.toUpperCase()}`,
      }));
    };

    setEditData(data);
    if (data.exchange === "binance") {
      setExOptions(getOptions(exchangeData.krakenPairs));
      setCurrantPairs(exchangeData.binancePairs);
      setSelectedPairs(createKeyValuePair(exchangeData.binancePairs));
    } else if (data.exchange === "kraken") {
      setExOptions(getOptions(exchangeData.binancePairs));
      setCurrantPairs(exchangeData.krakenPairs);
      setSelectedPairs(createKeyValuePair(exchangeData.krakenPairs));
    }
    handleShow();
  };

  const handlePermissionsTabsSubmit = async () => {
    const values = selectedPairs.map((coinId) => coinId.value);
    const result = await updateExchangeCredentials({
      credentialId: editData.id,
      coinIds: values,
      exchange: editData.exchange,
    });
    toast.dismiss();
    if (result?.data?.status) {
      toast.success(result.data.message);
      refetch();
      refetchExInfo();
      handleModalClose();
    } else {
      toast.error(result?.data?.message || "Error occurred");
    }
  };

  const selectedOptions = useMemo(() => {
    return currantPairs?.map((pair) => ({
      value: pair.pair_id,
      label: `${pair.name1?.toUpperCase()}/${pair.name2?.toUpperCase()}`,
    }));
  }, [currantPairs]);

  const options = useMemo(() => {
    return exOptions?.map((pair) => ({
      value: pair.id,
      label: `${pair.first_coin_symbol?.toUpperCase()}/${pair.second_coin_symbol?.toUpperCase()}`,
    }));
  }, [exOptions]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Exchange Management</h3>
            <div className=" ms-auto"></div>
          </div>

          {exchangeLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <ExchangeManagementTable
                  data={exchangeData?.exChangeInfo}
                  onEdit={handleEdit}
                />
              </div>
            </div>
          )}
        </div>
        <CustomModal
          isOpen={show}
          onClose={handleModalClose}
          title={"Add Pairs"}
          footer={
            <Button
              className="btn btn1"
              variant={"primary"}
              isLoading={updatingExchange}
              onClick={handlePermissionsTabsSubmit}
            >
              Update
            </Button>
          }
        >
          {editData && (
            <Select
              defaultValue={selectedOptions}
              isMulti
              name="colors"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              isLoading={isLoading}
              onChange={(selected) => setSelectedPairs(selected)}
            />
          )}
        </CustomModal>
      </div>
    </div>
  );
};

export default ExchangeManagement;
