import React, { useEffect, useMemo } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import { useGetIbPermissionsQuery } from "../../../redux/brokerApi";

const SubIBTabs = ({ coins, onCoin, ibId }) => {
  const {
    data: permissions,
    isLoading,
    refetch,
    isFetching,
  } = useGetIbPermissionsQuery({ ibId });
  const options = useMemo(() => {
    return coins?.map((cryptoCoin) => ({
      value: cryptoCoin.id,
      label: cryptoCoin.name,
    }));
  }, [coins]);

  const selectedOptions = useMemo(() => {
    return permissions?.coins?.map((cryptoCoin) => ({
      value: cryptoCoin.coin_id,
      label: cryptoCoin.name,
    }));
  }, [permissions, ibId]);

  useEffect(() => {
    if (selectedOptions?.length > 0) {
      onCoin(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Tabs>
      <TabList>
        <Tab>Coins</Tab>
        <Tab disabled>Equity</Tab>
        <Tab disabled>Fx Trade</Tab>
      </TabList>

      <TabPanel>
        <h4>Select crypto coins</h4>
        {isFetching ? (
          <div>Loading</div>
        ) : (
          <Select
            defaultValue={selectedOptions}
            isMulti
            name="colors"
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            isLoading={isLoading}
            onChange={(selected) => onCoin(selected)}
          />
        )}
      </TabPanel>
      <TabPanel>
        <h4>Any content 2</h4>
      </TabPanel>
      <TabPanel>
        <h4>Any content 3</h4>
      </TabPanel>
    </Tabs>
  );
};

export default SubIBTabs;
