// UserList.jsx
import React, { useState } from "react";
import CustomModal from "../../widgets/Modal";
import { useGetAdminsQuery } from "../../../redux/adminApi";
import TableLoader from "../../widgets/TableLoader";
import Profile from "./Profile";
import Button from "../../widgets/Button";
import {
  UserPopupTabs,
  UsersTable,
  HeadWrapper,
  UserStatus,
} from "../Elements";
import { useSelector } from "react-redux";
import PageLoader from "../../widgets/PageLoader";

const AdminList = () => {
  const {
    permissions: { admin },
  } = useSelector((state) => state.auth);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { data: records, isLoading, refetch } = useGetAdminsQuery();
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [showTabs, setShowTabs] = useState(false);
  const [adminData, setAdminData] = useState(null);

  const handlePermissions = (data) => {
    setShowTabs(true);
    setAdminData(data);
  };

  const handleAddClick = () => {
    setShowAddPopup(true);
    setIsNew(true);
  };

  const handlePopupClose = () => {
    setShowAddPopup(false);
    setStatusData(null);
    setEditData(null);
    setIsEdit(false);
    setIsStatus(false);
    setIsNew(false);
    setTabIndex(0);
  };

  const handleEdit = (data, tabIndex) => {
    setEditData(data);
    setIsEdit(true);
    setShowAddPopup(true);
    setIsNew(false);

    if (tabIndex) {
      setTabIndex(tabIndex);
    }
  };

  const handleStatus = (data) => {
    setStatusData(data);
    setIsStatus(true);
    setShowAddPopup(true);
  };

  const handlePermissionsTabsSubmit = async () => {
    // const values = selectedCoins.map((coinId) => coinId.value);
    // const result = await assignIBCoins({ ibId: ibData.id, coinIds: values });
    // handleToast(result);
    // if (result?.data?.status) {
    //   setShowTabs(false);
    // }
  };

  if (!admin?.permissions) {
    return <PageLoader />;
  }

  return (
    <HeadWrapper
      title="Admins"
      button={
        admin?.permissions?.status?.write && (
          <button className="btn btn1" onClick={handleAddClick}>
            Add Admin
          </button>
        )
      }
    >
      {isLoading ? (
        <TableLoader />
      ) : (
        <div className="white-box">
          <div className="table-responsive">
            <UsersTable
              data={records}
              onEdit={handleEdit}
              onPermission={handlePermissions}
              onStatus={handleStatus}
              access={admin?.permissions.status}
            />
          </div>
        </div>
      )}

      {isStatus && showAddPopup && (
        <UserStatus
          id={statusData.id}
          onClose={handlePopupClose}
          show={isStatus}
          refetch={refetch}
          status={statusData.status}
        />
      )}

      {isNew && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="1000px"
          title="Add Admin"
        >
          <UserPopupTabs
            isNew={isNew}
            onClose={handlePopupClose}
            refetch={refetch}
            getNewUser={handleEdit}
            Profile={Profile}
            tabIndex={tabIndex}
            handleTabIndex={setTabIndex}
          />
        </CustomModal>
      )}

      {isEdit && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="1000px"
          title="Edit Admin"
        >
          <UserPopupTabs
            isEdit={isEdit}
            editData={editData}
            refetch={refetch}
            onClose={handlePopupClose}
            getNewUser={handleEdit}
            Profile={Profile}
            handleTabIndex={setTabIndex}
            tabIndex={tabIndex}
          />
        </CustomModal>
      )}
      {showTabs && (
        <CustomModal
          isOpen={showTabs}
          onClose={() => setShowTabs(false)}
          title={"Add Permissions"}
          footer={
            <Button
              className="btn btn1"
              variant={"primary"}
              // isLoading={assigningIbCoins}
              onClick={handlePermissionsTabsSubmit}
            >
              Submit
            </Button>
          }
        >
          {/* <IBTabs
            coins={cryptoCoins}
            onCoin={handleCoinChange}
            ibId={ibData?.id}
          /> */}
        </CustomModal>
      )}
    </HeadWrapper>
  );
};

export default AdminList;
