import { apiSlice } from "./api";
import {
  getBuyOrdersApi,
  getSellOrdersApi,
  getBuyOrdersFxApi,
  getSellOrdersFxApi,
  userHistoryApi,
} from "../components/constant/Api";

export const ReportsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuyOrders: builder.query({
      query: () => ({
        url: getBuyOrdersApi,
        method: "GET",
      }),
      invalidatesTags: (_) => ["buyOrders"],
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getSellOrders: builder.query({
      query: () => ({
        url: getSellOrdersApi,
        method: "GET",
      }),
      invalidatesTags: (_) => ["sellOrders"],
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getBuyOrdersFx: builder.query({
      query: () => ({
        url: getBuyOrdersFxApi,
        method: "GET",
      }),
      invalidatesTags: (_) => ["buyOrdersFx"],
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getSellOrdersFx: builder.query({
      query: () => ({
        url: getSellOrdersFxApi,
        method: "GET",
      }),
      invalidatesTags: (_) => ["sellOrdersFx"],
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    userHistoryByType: builder.query({
      query: ({ userId, type }) => ({
        url: `${userHistoryApi}/${userId}/${type}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {
  useGetBuyOrdersQuery,
  useGetSellOrdersQuery,
  useUserHistoryByTypeQuery,
  useGetBuyOrdersFxQuery,
  useGetSellOrdersFxQuery,
} = ReportsApi;
