import { baseUrl } from "./BaseUrl";

export const loginApi = baseUrl + "/login";
export const changePasswordApi = baseUrl + "/change-password";

// ===================== users =========================
export const getUsersApi = baseUrl + "/users";
export const getUserPermissionsApi = baseUrl + "/user-permissions";
export const userStatus = baseUrl + "/user-status";
export const addBalanceApi = baseUrl + "/add-balance";
export const assetPermissions = baseUrl + "/asset-permissions";
export const getAssetPairs = baseUrl + "/get-asset-pairs";

// =============== dashboard ============
export const getDashboard = baseUrl + "/dashboard";

// ==================== brokers =========================
export const getBrokersApi = baseUrl + "/brokers";
export const getBrokersOfSuperAdminApi = baseUrl + "/brokersOfSuperAdmin";
export const postBrokerApi = baseUrl + "/add-broker";
export const updateBrokerApi = baseUrl + "/update-broker";
export const deleteBrokerApi = baseUrl + "/delete-broker";
export const assignCoinsIBApi = baseUrl + "/assign-coins-ib";
export const getIBPermissionsApi = baseUrl + "/ib-permissions";
export const getBrokerCredentialsApi = baseUrl + "/broker-credentials";
export const postBrokerCredentialsApi = baseUrl + "/broker-credentials";
export const getPermissions = baseUrl + "/get-permissions";

// ==================== ibs =========================
export const getIbsApi = baseUrl + "/ibs";
export const postIbApi = baseUrl + "/add-ib";
export const updateIbApi = baseUrl + "/update-ib";
export const deleteIbApi = baseUrl + "/delete-ib";

// ====================sub ibs =========================
export const getSubIbsApi = baseUrl + "/subIbs";
export const postSubIbApi = baseUrl + "/add-subIb";
export const updateSubIbApi = baseUrl + "/update-subIb";
export const deleteSubIbApi = baseUrl + "/delete-subIb";

// ==================== admins =========================
export const getAdminApi = baseUrl + "/admins";
export const postAdminApi = baseUrl + "/add-admin";
export const updateAdminApi = baseUrl + "/update-admin";
export const deleteAdminApi = baseUrl + "/delete-admin";

// =====================crypto ====================
export const getCryptoApi = baseUrl + "/cryptos";
export const postCryptoApi = baseUrl + "/add-crypto";
export const updateCryptoApi = baseUrl + "/update-crypto";
export const deleteCryptoApi = baseUrl + "/delete-crypto";

// =====================crypto pairs ====================
export const getCryptoPairsApi = baseUrl + "/pairs";
export const postCryptoPairsApi = baseUrl + "/add-pair";
export const updateCryptoPairsApi = baseUrl + "/update-pair";
export const deleteCryptoPairsApi = baseUrl + "/delete-pair";

// ===================== support ======================
export const getAllRequestApi = baseUrl + "/user-requests";
export const getRequestInfoApi = baseUrl + "/user-request-info";
export const postRequestChatsApi = baseUrl + "/user-request-chats";
export const replyAdminApi = baseUrl + "/admin-reply";
export const userRequestStatusApi = baseUrl + "/user-request-status";

// ==================exchangeCredentials =============================
export const getExchangeCredentialsApi = baseUrl + "/exchange-credentials";
export const updateExchangeCredentialsApi =
  baseUrl + "/exchange-credentials-update";
export const deleteOrdersApi = baseUrl + "/delete-order-admin";
export const deleteCloseOrdersFxApi = baseUrl + "/delete-order-admin-fx";
export const deleteCloseOrdersEqApi = baseUrl + "/delete-order-admin-eq";

// ===================== fx ====================
export const getFxPairs = baseUrl + "/fx-pairs";
export const updateFxCoin = baseUrl + "/update-fx";

// =====================commission ====================

export const getCommissionsApi = baseUrl + "/commissions";
export const getPairsCommissionApi = baseUrl + "/get-pairs-commissions";
export const postCommissionsBulkApi = baseUrl + "/add-commission-bulk";
export const getCommissionsReportsApi = baseUrl + "/commission-reports";
export const postCommissionsApi = baseUrl + "/add-commission";
export const updateCommissionsApi = baseUrl + "/update-commission";
export const deleteCommissionsApi = baseUrl + "/delete-commission";
export const getAssetCommissionApi = baseUrl + "/get-asset-commission";
export const postAssetCommissionApi = baseUrl + "/add-asset-commission";
export const updateAssetCommissionApi = baseUrl + "/update-asset-commission";
export const getCommissionsFxApi = baseUrl + "/commissions-fx";
export const getPairsCommissionFxApi = baseUrl + "/get-pairs-commissions-fx";
export const postCommissionsFxApi = baseUrl + "/add-commission-fx";
export const updateCommissionsFxApi = baseUrl + "/update-commission-fx";
export const deleteCommissionsFxApi = baseUrl + "/delete-commission-fx";
export const postCommissionsFxBulkApi = baseUrl + "/add-commission-fx-bulk";

// ==================spread ===========================
export const getSpreadsApi = baseUrl + "/spreads";
export const getCryptoSpreadsApi = baseUrl + "/get-crypto-spreads";
export const postCryptoSpreadsApi = baseUrl + "/add-crypto-spread";
export const updateCryptoSpreadsApi = baseUrl + "/update-crypto-spread";
export const deleteCryptoSpreadsApi = baseUrl + "/delete-crypto-spread";

// =================notifications ===================
export const getNotificationsApi = baseUrl + "/notifications";
export const postNotificationsApi = baseUrl + "/notifications";
// export const getNotificationsApi = baseUrl + "/notifications";

// =====================risk info ====================
export const getRiskInfoApi = baseUrl + "/get-risk-info";
export const postRiskInfoApi = baseUrl + "/add-risk-info";
export const updateRiskInfoApi = baseUrl + "/update-risk-info";

// ============== kyc manage ==================
export const getKycApi = baseUrl + "/kyc";

// =============leverages ===================
export const postLeverageApi = baseUrl + "/add-leverage";
export const getLeverageApi = baseUrl + "/get-leverages";
export const leverageStatusApi = baseUrl + "/leverage-status";
export const getLeveragesPermissionsApi = baseUrl + "/get-leverage-permissions";
export const leveragePermissionsApi = baseUrl + "/leverage-permissions";

// ===================== vendor management ======================
// ===================== kyc vendor management ======================
export const getKycVendorApi = baseUrl + "/kyc-vendor";
export const postKycVendorApi = baseUrl + "/add-kyc-vendor";
export const updateKycVendorApi = baseUrl + "/update-kyc-vendor";
export const deleteKycVendorApi = baseUrl + "/delete-kyc-vendor";
export const disableKycVendorApi = baseUrl + "/disable-kyc-vendor";
// ===================== clearing house management ======================
export const getClearingHouseApi = baseUrl + "/clearing-house";
export const postClearingHouseApi = baseUrl + "/add-clearing-house";
export const updateClearingHouseApi = baseUrl + "/update-clearing-house";
export const deleteClearingHouseApi = baseUrl + "/delete-clearing-house";
export const disableClearingHouseApi = baseUrl + "/disable-clearing-house";

// ===================== data provider management ======================
export const getDataProviderApi = baseUrl + "/data-provider";
export const postDataProviderApi = baseUrl + "/add-data-provider";
export const updateDataProviderApi = baseUrl + "/update-data-provider";
export const deleteDataProviderApi = baseUrl + "/delete-data-provider";
export const disableDataProviderApi = baseUrl + "/disable-data-provider";
// ===================== end vendor management ======================

// ======================== reports ==================================
export const getBuyOrdersApi = baseUrl + "/buy-orders";
export const getSellOrdersApi = baseUrl + "/sell-orders";
export const getBuyOrdersFxApi = baseUrl + "/buy-orders-fx";
export const getSellOrdersFxApi = baseUrl + "/sell-orders-fx";
export const userHistoryApi = baseUrl + "/user-history";

// ======================== url management ==================================
export const getUrlManagementApi = baseUrl + "/url-management";
export const updateUrlManagementApi = baseUrl + "/update-url-management";
