import React, { useState, useMemo } from "react";
import {
  useGetUrlManagementQuery,
  useUpdateUrlManagementMutation,
} from "../../../redux/urlManagementApi";
import { useGetCryptosQuery } from "../../../redux/cryptoApi";
import TableLoader from "../../widgets/TableLoader";
import UrlManagementTable from "./UrlManagementTable";
import {
  ValidateInputFields,
  isNegative,
} from "../../validations/ValidateInputFields";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";

const UrlManagement = () => {
  const {
    data: urlData = [],
    isLoading,
    refetch,
    isFetching,
  } = useGetUrlManagementQuery();

  const { data: cryptoCoins } = useGetCryptosQuery();

  const [updateUrl, { isLoading: updatingUrl }] =
    useUpdateUrlManagementMutation();

  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [crypto, setUrl] = useState({
    firstCoin: { value: "", required: true },
    secondCoin: { value: "", required: true },
  });

  const [urlError, setUrlError] = useState({
    firstCoin: "",
    secondCoin: "",
  });

  const handleClose = () => {
    setUrl({
      firstCoin: { value: "", required: true },
      secondCoin: { value: "", required: true },
    });
    setUrlError(null);
    setEditData(null);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);

    setUrl({
      firstCoin: { value: data.firstCoinId, required: true },
      secondCoin: { value: data.secondCoinId, required: true },
    });
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUrl((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    let validationError = ValidateInputFields(
      name,
      value,
      crypto[name].required
    );

    if (validationError) {
      setUrlError((prevError) => {
        return { ...prevError, [name]: validationError };
      });
      return;
    }
    validationError = isNegative(value);
    setUrlError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(crypto).map(([key, { value }]) => [key, value])
      );

      for (let key in crypto) {
        let validationError = ValidateInputFields(
          key,
          crypto[key].value,
          crypto[key].required
        );
        setUrlError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
        validationError = isNegative(crypto[key].value);
        if (validationError) return;
      }

      // return;

      const mutationResult = await updateUrl({
        id: editData.id,
        ...values,
      });

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleToast = (result) => {
      toast.dismiss();
      if (result?.data?.status) {
        toast.success(result.data.message);
      } else {
        toast.error(result?.data?.message || "Error occurred");
      }
    };

    handleUpdateOrAdd();
  };

  const selectorBody = useMemo(() => {
    return cryptoCoins?.map((cryptoCoin) => (
      <option value={cryptoCoin.id} key={cryptoCoin.id}>
        {cryptoCoin.name}
      </option>
    ));
  }, [cryptoCoins]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">URL Management</h3>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div
                className="table-responsive"
                disabled={isFetching ? true : false}
              >
                <UrlManagementTable
                  data={urlData}
                  onEdit={handleEdit}
                  //   onDelete={handleDelete}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title="Edit Url"
        footer={
          <Button
            className="btn btn1"
            variant={"primary"}
            isLoading={updatingUrl}
            onClick={handleSubmit}
          >
            Update
          </Button>
        }
      >
        {
          <form>
            <div className="form-group">
              <label className="fw-bold">First Coin</label>
              <select
                className="form-select shadow-none  "
                id="planets-selector"
                name="firstCoin"
                value={crypto.firstCoin?.value}
                onChange={handleChange}
                // disabled={isEdit || user.role !== "Global Admin"}
              >
                <option value="">Select Coin</option>
                {selectorBody?.filter(
                  (option) => option.key !== `${crypto.name?.value}`
                )}
              </select>
              <span style={{ color: "red" }}>{urlError?.type}</span>
            </div>

            <div className="form-group">
              <label className="fw-bold">Second Coin</label>
              <select
                className="form-select shadow-none  "
                id="planets-selector"
                name="secondCoin"
                value={crypto.secondCoin?.value}
                onChange={handleChange}
                // disabled={isEdit || user.role !== "Global Admin"}
              >
                <option value="">Select Coin</option>
                {selectorBody?.filter(
                  (option) => option.key !== `${crypto.name?.value}`
                )}
              </select>
              <span style={{ color: "red" }}>{urlError?.type}</span>
            </div>
          </form>
        }
      </CustomModal>
    </div>
  );
};

export default UrlManagement;
