import React, { useState, useMemo, useEffect } from "react";
import {
  useAddCryptoSpreadMutation,
  useGetCryptoSpreadsQuery,
  useUpdateCryptoSpreadMutation,
} from "../../../redux/spreadApi";
import {
  ValidateInputFields,
  isNegative,
} from "../../validations/ValidateInputFields";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import Select from "react-select";
import { useSelector } from "react-redux";

const SpreadCrypto = ({ isEdit, editData, handleClose }) => {
  const { user } = useSelector((state) => state.auth);

  const { data: cryptoPairs, refetch } = useGetCryptoSpreadsQuery({
    type: "crypto",
  });

  const [addSpread, { isLoading: addingCryptoSpread }] =
    useAddCryptoSpreadMutation();

  const [updateCryptoSpread, { isLoading: updatingCryptoSpread }] =
    useUpdateCryptoSpreadMutation();

  const [selectedPairs, setSelectedPairs] = useState([]);
  const [spread, setSpread] = useState({
    pairId: { value: "", required: true },
    type: { value: "", required: true },
    rateType: { value: "", required: true },
    spreadPercentage: { value: "", required: true },
    totalSpreadPercentage: { value: "", required: false },
  });
  const [spreadError, setSpreadError] = useState({
    pairId: "",
    type: "",
    rateType: "",
    spreadPercentage: "",
    totalSpreadPercentage: "",
  });

  useEffect(() => {
    if (cryptoPairs && editData) {
      const { spread_percentage } = cryptoPairs.find(
        (pair) => pair.id === editData.pairId
      );

      const totalSpread = spread_percentage
        ? spread_percentage + Number(editData.spread_percentage)
        : editData.spread_percentage;
      setSelectedPairs({
        value: editData.pairId,
        label: `${editData.first_coin_symbol}/${editData.second_coin_symbol}`,
        spread_percentage: spread_percentage,
      });
      setSpread({
        pairId: { value: editData.pairId, required: true },
        spreadPercentage: { value: editData.spread_percentage, required: true },
        totalSpreadPercentage: {
          value: totalSpread,
          required: false,
        },
        type: { value: editData.type, required: true },
        rateType: { value: editData.rate_type, required: true },
      });
    }
  }, [cryptoPairs, editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setSpread((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    if (name === "spreadPercentage") {
      console.log(
        selectedPairs?.spread_percentage,
        "selectedPairs?.spread_percentage"
      );
      const totalSpread = selectedPairs?.spread_percentage
        ? selectedPairs.spread_percentage + Number(value)
        : value;

      setSpread((prev) => {
        return {
          ...prev,
          totalSpreadPercentage: { ...prev[name], value: `${totalSpread}` },
        };
      });
    }

    let validationError = ValidateInputFields(
      name,
      value,
      spread[name].required
    );

    if (validationError) {
      setSpreadError((prevError) => {
        return { ...prevError, [name]: validationError };
      });
      return;
    }
    validationError = isNegative(value);
    setSpreadError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSelectChange = (selected) => {
    const { type, spread_percentage, rate_type } = cryptoPairs.find(
      (pair) => pair.id === selected.value
    );

    const totalSpread = spread_percentage
      ? spread_percentage + Number(spread["totalSpreadPercentage"].value)
      : 0;

    setSelectedPairs({ ...selected, spread_percentage });
    setSpread((prev) => ({
      ...prev,
      pairId: { value: `${selected.value}`, required: true },
      type: { value: type || "", required: true },
      rateType: { value: rate_type || "", required: true },
      totalSpreadPercentage: { value: `${totalSpread}`, required: false },
    }));
    const validationError = ValidateInputFields(
      "pairId",
      `${selected.value}`,
      spread["pairId"].required
    );

    setSpreadError((prevError) => {
      return { ...prevError, pairId: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(spread).map(([key, { value }]) => [key, value])
      );

      for (let key in spread) {
        let validationError = ValidateInputFields(
          key,
          spread[key].value,
          spread[key].required
        );
        setSpreadError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
        validationError = isNegative(spread[key].value);
        if (validationError) return;
      }

      console.log(values, "valuies");

      // return;

      const mutationResult = isEdit
        ? await updateCryptoSpread({
            id: editData.id,
            ...values,
            assetType: "crypto",
          })
        : await addSpread({ ...values, assetType: "crypto" });

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose(0);
      }
    };

    const handleToast = (result) => {
      toast.dismiss();
      if (result?.data?.status) {
        toast.success(result.data.message);
      } else {
        toast.error(result?.data?.message || "Error occurred");
      }
    };

    handleUpdateOrAdd();
  };
  const options = useMemo(() => {
    return cryptoPairs?.map((pair) => ({
      value: pair.id,
      label: `${pair.first_coin_symbol.toUpperCase()}/${pair.second_coin_symbol.toUpperCase()}`,
    }));
  }, [cryptoPairs]);

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fw-bold">Pair Name*</label>
          {isEdit ? (
            <input
              type="text"
              className="form-control"
              placeholder="Enter buy commission"
              name="spreadPercentage"
              onChange={handleChange}
              value={selectedPairs.label}
              disabled
            />
          ) : (
            <Select
              defaultValue={selectedPairs}
              name="pairId"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              // isLoading={isLoading}
              onChange={handleSelectChange}
            />
          )}
          <span style={{ color: "red" }}>{spreadError?.pairId}</span>
        </div>
        <div className="form-group">
          <label className="fw-bold">Select Direction Type*</label>
          <select
            className="form-select shadow-none  "
            id="planets-selector"
            name="type"
            value={spread.type?.value}
            onChange={handleChange}
            disabled={user.role !== "Global Admin"}
          >
            <option value="">Select Type</option>
            <option value="up">Up</option>
            <option value="down">Down</option>
          </select>
          <span style={{ color: "red" }}>{spreadError?.type}</span>
        </div>
        <div className="form-group">
          <label className="fw-bold">Select Rate Type*</label>
          <select
            className="form-select shadow-none  "
            id="planets-selector"
            name="rateType"
            value={spread.rateType?.value}
            onChange={handleChange}
            disabled={user.role !== "Global Admin"}
          >
            <option value="">Select Type</option>
            <option value="flat">Flat</option>
            <option value="percentage">Percentage</option>
          </select>
          <span style={{ color: "red" }}>{spreadError?.rateType}</span>
        </div>
        <div className="form-group">
          <label className="fw-bold">Spread Rate*</label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Spread Percentage"
            name="spreadPercentage"
            onChange={handleChange}
            value={spread.spreadPercentage.value}
          />
          <span style={{ color: "red" }}>{spreadError?.spreadPercentage}</span>
        </div>
        {user.role !== "Global Admin" && (
          <div className="form-group">
            <label className="fw-bold">Total Spread Percentage*</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter Total Spread Percentage"
              name="totalSpreadPercentage"
              onChange={handleChange}
              value={spread.totalSpreadPercentage.value}
              disabled
            />
            <span style={{ color: "red" }}>
              {spreadError?.totalSpreadPercentage}
            </span>
          </div>
        )}
        <Button
          className="btn btn1"
          variant={"primary"}
          isLoading={isEdit ? updatingCryptoSpread : addingCryptoSpread}
          onClick={handleSubmit}
        >
          {isEdit ? "Update" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default SpreadCrypto;
