import { apiSlice } from "./api";
import {
  getUsersApi,
  getUserPermissionsApi,
  userStatus,
  addBalanceApi,
  getPermissions,
  assetPermissions,
  getAssetPairs,
} from "../components/constant/Api";

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: getUsersApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getUserPermissions: builder.mutation({
      query: () => ({
        url: getUserPermissionsApi,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    userStatus: builder.mutation({
      query: (post) => ({
        url: userStatus,
        method: "POST",
        body: post,
      }),
    }),
    addBalance: builder.mutation({
      query: (post) => ({
        url: addBalanceApi,
        method: "POST",
        body: post,
      }),
    }),
    getPermissions: builder.query({
      query: ({ userId, userAddType }) => ({
        url: `${getPermissions}/${userId}/${userAddType}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getAssetPairs: builder.query({
      query: ({ userId, type }) => ({
        url: `${getAssetPairs}/${userId}/${type}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    assetPermission: builder.mutation({
      query: (post) => ({
        url: assetPermissions,
        method: "POST",
        body: post,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserPermissionsMutation,
  useUserStatusMutation,
  useGetPermissionsQuery,
  useGetAssetPairsQuery,
  useAssetPermissionMutation,
  useAddBalanceMutation,
} = usersApi;
