import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../widgets/Button";
import ChatRoomBox from "./ChatRoomBox";
import ReplyBox from "./ReplyBox";
import toast from "react-hot-toast";
import {
  useGetUserRequestInfoQuery,
  useGetUserRequestChatsQuery,
  useReplyAdminMutation,
  useUserRequestStatusMutation,
} from "../../../redux/supportApi";
import { ValidateInputFields } from "../../validations/ValidateInputFields";

const ChatRoom = () => {
  const { ticketId } = useParams();
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [messageError, setMessageError] = useState({
    message: "",
  });
  const { data: requestInfo, isLoading: loadingInfo } =
    useGetUserRequestInfoQuery({ ticketId });
  const {
    data: chats,
    isLoading: chatsLoading,
    refetch,
  } = useGetUserRequestChatsQuery({
    ticketId,
  });
  const [adminReply, { isLoading: replyLoading }] = useReplyAdminMutation();
  const [updateStatus] = useUserRequestStatusMutation();

  useEffect(() => {
    if (requestInfo) {
      setStatus(requestInfo?.status);
    }
  }, [requestInfo]);

  const handleChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setMessage(value);
    const validationError = ValidateInputFields(name, value, true);
    setMessageError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleAdminReply = async (e) => {
    e.preventDefault();

    const validationError = ValidateInputFields("message", message, true);

    if (validationError) {
      setMessageError((prevError) => {
        return { ...prevError, message: validationError };
      });
      return;
    }

    const formData = new FormData();
    formData.append("message", message);
    formData.append("ticketId", ticketId);
    formData.append("userId", requestInfo?.userId);
    formData.append("replyBy", "1");

    if (file) {
      formData.append("adminImage", file);
    }

    const result = await adminReply(formData);

    if (result) {
      toast.dismiss();
      if (result?.data?.status) {
        toast.success(result.data.message);
        setFile(null);
        setMessage("");
        refetch();
      } else {
        toast.error(result?.data?.message || "Error occurred");
      }
    }
  };

  const handleToast = (result) => {
    toast.dismiss();
    if (result?.data?.status) {
      toast.success(result.data.message);
    } else {
      toast.error(result?.data?.message || "Error occurred");
    }
  };

  const handleStatusUpdate = async (e) => {
    e.preventDefault();

    const result = await updateStatus({ ticketId, status });
    handleToast(result);
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="d-md-flex mb-1">
          <h3 className="page-title">Reply</h3>
        </div>
        <div className="chat_box">
          <div className="row">
            <div className="col-md-6 pt-2">
              <input type="reset" defaultValue="Reset" className="d-none" />
              <b>Ticket Id:</b> {ticketId}
            </div>

            <div className="col-md-6">
              <div className="status_box">
                <div className="pt-2 ">
                  <b>Status:</b>
                </div>
                <div className="ms-md-3 me-md-3">
                  <select
                    className="form-select box-shadow-none"
                    id="status"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  >
                    <option value="0">Pending</option>
                    <option value="1">Resolved</option>
                  </select>
                </div>

                <Button
                  className="btn btn1"
                  onClick={handleStatusUpdate}
                  type="submit"
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <h4 className="text-uppercase">Subject: {requestInfo?.issue}</h4>
        <div className="w_chating_box">
          <ChatRoomBox
            isLoading={chatsLoading || loadingInfo}
            requestInfo={requestInfo}
            chats={chats}
          />

          {status === "0" ? (
            <ReplyBox
              message={message}
              setMessage={setMessage}
              file={file}
              setFile={setFile}
              isLoading={replyLoading}
              adminReply={handleAdminReply}
              handleChange={handleChange}
              error={messageError}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
