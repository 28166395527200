import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import Profile from "./Profile";
import {
  CryptoCommission,
  StockCommission,
  FuturesCommission,
} from "../commission";
import AssetTabs from "./AssetTabs";
import { CryptoRiskInfo, FuturesRiskInfo, StockRiskInfo } from "../riskInfo";
import { Leverage } from "./Leverage";

export const UserPopupTabs = ({
  isEdit,
  editData,
  isNew,
  refetch,
  onClose,
  getNewUser,
  Profile,
  tabIndex,
  handleTabIndex,
}) => {
  console.log(editData, "TABBB");
  return (
    <div className="parent-tabs">
      <Tabs
        className="level-1-panels row"
        selectedIndex={tabIndex}
        onSelect={(index) => handleTabIndex(index)}
      >
        <div className="col-md-3">
          <TabList className="vertical-tabs left-tabs">
            <Tab>Profile</Tab>
            <Tab disabled={isNew}>Asset Permissions</Tab>
            <Tab disabled={isNew}>Commission</Tab>
            <Tab disabled={isNew}>Risk Info</Tab>
            {isEdit && editData.role !== "Admin" && (
              <Tab disabled={isNew}>Leverage</Tab>
            )}
          </TabList>
        </div>
        <div className="col-md-9 scrool_right_data">
          <div>
            <TabPanel>
              {isEdit ? (
                <Profile
                  onClose={onClose}
                  refetch={refetch}
                  isEdit={isEdit}
                  data={editData}
                  getNewUser={getNewUser}
                />
              ) : (
                <Profile
                  onClose={onClose}
                  refetch={refetch}
                  isNew={isNew}
                  getNewUser={getNewUser}
                />
              )}
            </TabPanel>
            <TabPanel>
              <AssetTabs isEdit={isEdit} editData={editData} />
            </TabPanel>
            <TabPanel>
              <Tabs>
                <TabList>
                  <Tab>Crypto Commission</Tab>
                  <Tab>FX Commission</Tab>
                  <Tab disabled>Equity Commission</Tab>
                </TabList>
                <TabPanel>
                  {isEdit && (
                    <CryptoCommission
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                      editData={editData}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {isEdit && (
                    <FuturesCommission
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                      editData={editData}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {isEdit && (
                    <StockCommission
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                    />
                  )}
                </TabPanel>
              </Tabs>
            </TabPanel>
            <TabPanel>
              <Tabs>
                <TabList>
                  <Tab>Crypto Risk Info</Tab>
                  <Tab>FX Risk Info</Tab>
                  <Tab>Equity Risk Info </Tab>
                </TabList>
                <TabPanel>
                  {isEdit && (
                    <CryptoRiskInfo
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {isEdit && (
                    <FuturesRiskInfo
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {isEdit && (
                    <StockRiskInfo
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                    />
                  )}
                </TabPanel>
              </Tabs>
            </TabPanel>
            <TabPanel>
              {isEdit && editData.role !== "Admin" && (
                <Leverage
                  isEdit={isEdit}
                  editData={editData}
                  selectChange={() => console.log("hi")}
                />
              )}
            </TabPanel>
          </div>
        </div>
      </Tabs>
    </div>
  );
};
