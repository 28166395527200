import React, { useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import CryptoTable from "./CryptoTable";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { handleToast } from "../../utils/handleToast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import {
  useAddKycVendorMutation,
  useGetKycVendorQuery,
  useDeleteKycVendorMutation,
  useDisableKycVendorMutation,
  useUpdateKycVendorMutation,
} from "../../../redux/kycVendorApi";

const Crypto = () => {
  const { data: records, isLoading, refetch } = useGetKycVendorQuery();
  const [addKycVendor, { isLoading: addingKycVendor }] =
    useAddKycVendorMutation();
  const [deleteKycVendor, { isLoading: deletingKycVendor }] =
    useDeleteKycVendorMutation();

  const [disableKycVendor, { isLoading: disablingKycVendor }] =
    useDisableKycVendorMutation();

  const [updateKycVendor, { isLoading: updatingKycVendor }] =
    useUpdateKycVendorMutation();

  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [isDisable, setIsDisable] = useState(false);
  const [disableData, setDisableData] = useState(null);

  const [disabledStatus, setDisabledStatus] = useState(0);
  const [show, setShow] = useState(false);
  const [vendor, setVendor] = useState({
    name: { value: "", required: true },
    secret_key: { value: "", required: true },
    api_key: { value: "", required: true },
  });
  const [vendorError, setVendorError] = useState({
    name: "",
    secret_key: "",
    api_key: "",
  });

  const handleClose = () => {
    setVendor({
      name: { value: "", required: true },
      secret_key: { value: "", required: true },
      api_key: { value: "", required: true },
    });
    setVendorError(null);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
    setIsDisable(false);
  };
  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    setVendor({
      name: { value: data.name, required: true },
      secret_key: { value: data.secret_key, required: true },
      api_key: { value: data.api_key, required: true },
    });
    handleShow();
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setIsDelete(true);
    handleShow();
  };

  const handleDisable = (data) => {
    setDisableData(data);
    setDisabledStatus(data.is_disabled);
    setIsDisable(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVendor((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      vendor[name].required
    );

    setVendorError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleDelete = async () => {
      const { id } = deleteData;
      const result = await deleteKycVendor({ id });

      handleToast(result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleDisable = async () => {
      const { id, is_disabled } = disableData;
      const result = await disableKycVendor({ id, is_disabled });

      handleToast(result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(vendor).map(([key, { value }]) => [key, value])
      );

      for (let key in vendor) {
        const validationError = ValidateInputFields(
          key,
          vendor[key].value,
          vendor[key].required
        );
        setVendorError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const mutationResult = isEdit
        ? await updateKycVendor({ id: editData.id, ...values })
        : await addKycVendor(values);

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    isDelete
      ? handleDelete()
      : isDisable
      ? handleDisable()
      : handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Crypto</h3>
            <div className=" ms-auto">
              <button
                disabled={addingKycVendor}
                className="btn btn1"
                onClick={handleShow}
              >
                Add Crypto
              </button>
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <CryptoTable
                  data={records}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onDisable={handleDisable}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={
          isDelete
            ? "Delete Kyc Vendor"
            : isDisable && disabledStatus === "0"
            ? "Enable Kyc Vendor"
            : isDisable && disabledStatus === "1"
            ? "Disable Kyc Vendor"
            : isEdit
            ? "Edit Kyc Vendor"
            : "Add Kyc Vendor"
        }
        footer={
          <Button
            className="btn btn1"
            variant={isDelete ? "danger" : "primary"}
            isLoading={
              isDelete
                ? deletingKycVendor
                : isDisable
                ? disablingKycVendor
                : isEdit
                ? updatingKycVendor
                : addingKycVendor
            }
            onClick={handleSubmit}
          >
            {isDelete
              ? "Delete"
              : isDisable && disabledStatus === "0"
              ? "Enable"
              : isDisable && disabledStatus === "1"
              ? "Disable"
              : isEdit
              ? "Update"
              : "Submit"}
          </Button>
        }
      >
        {isDelete ? (
          <div>Are you sure you want to delete?</div>
        ) : isDisable ? (
          <div>{`Are you sure you want to ${
            disabledStatus === "0" ? "enable" : "disable"
          }?`}</div>
        ) : (
          <form>
            <div className="form-group">
              <label className="fw-bold">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="name"
                onChange={handleChange}
                value={vendor.name.value}
              />
              <span style={{ color: "red" }}>{vendorError?.name}</span>
            </div>
            <div className="form-group">
              <label className="fw-bold">Secret Key</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Secret Key"
                name="secret_key"
                onChange={handleChange}
                value={vendor.secret_key.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>{vendorError?.secret_key}</span>
            </div>

            <div className="form-group">
              <label className="fw-bold">API Key</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter API Key"
                name="api_key"
                onChange={handleChange}
                value={vendor.api_key.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>{vendorError?.api_key}</span>
            </div>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default Crypto;
