import React, { useState, useMemo, useEffect } from "react";
import {
  useAddBrokerMutation,
  useUpdateBrokerMutation,
} from "../../../redux/brokerApi";
import { useGetAdminsQuery } from "../../../redux/adminApi";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import PhoneInput from "react-phone-input-2";
import RoleTable2 from "../Elements/RoleTable2";
import RoleTable from "../Elements/RoleTable";
import { useSelector } from "react-redux";
import cryptoManagementArray from "../Elements/permission";
import { useGetPermissionsQuery } from "../../../redux/usersApi";
import TableLoader from "../../widgets/TableLoader";

const Profile = ({ isEdit, data, isNew, refetch, onClose, getNewUser }) => {
  const { user } = useSelector((state) => state.auth);
  const [addBroker, { isLoading: addingBroker }] = useAddBrokerMutation();
  const [updateBroker, { isLoading: updatingBroker }] =
    useUpdateBrokerMutation();
  const { data: admins } = useGetAdminsQuery(
    {},
    { skip: user?.role === "Admin" }
  );
  const [userPermissions, setUserPermissions] = useState([]);
  const [broker, setBroker] = useState({
    name: { value: "", required: true },
    email: { value: "", required: true },
    mobile_number: { value: "", required: true },
    admin: { value: "", required: user?.role === "Admin" ? false : true },
    password: { value: "", required: true },
  });
  const [brokerError, setBrokerError] = useState({
    name: "",
    email: "",
    mobile_number: "",
    admin: "",
    password: "",
  });
  const {
    data: permissions,
    isLoading,
    isSuccess,
    refetch: refetchPermissions,
  } = useGetPermissionsQuery(
    {
      userId: isEdit
        ? data?.id
        : user?.role === "Global Admin" && broker.admin?.value
        ? broker.admin.value
        : user?.role === "Admin"
        ? user.id
        : data?.id,
      userAddType: "broker",
    },
    { skip: user?.role === "Admin" ? false : !broker.admin?.value }
  );

  useEffect(() => {
    if (data) {
      handleEdit(data);
    }
  }, [data, isSuccess, permissions]);

  useEffect(() => {
    if (permissions) {
      console.log(permissions, "permissionsbbb");
      setUserPermissions(permissions);
    }
  }, [permissions]);

  const handleEdit = (data) => {
    setBroker({
      name: { value: data.name, required: true },
      email: { value: data.email, required: true },
      mobile_number: { value: `${data.mobile_number}`, required: true },
      admin: {
        value: data.refred_by_id,
        required: user?.role === "Admin" ? false : true,
      },
      password: { value: "", required: false },
      permissions: {
        value: permissions,
        required: false,
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBroker((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      broker[name].required
    );

    if (name === "adminId") {
      refetchPermissions();
    }
    setBrokerError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const selectorBody = useMemo(() => {
    return admins?.map((cryptoCoin) => (
      <option value={cryptoCoin.id} key={cryptoCoin.id}>
        {cryptoCoin.name}
      </option>
    ));
  }, [admins]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(broker).map(([key, { value }]) => [key, value])
      );

      for (let key in broker) {
        const validationError = ValidateInputFields(
          key,
          broker[key].value,
          broker[key].required
        );
        setBrokerError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const isAdminPermissionValid = values.permissions
        .flatMap(({ permissions }) => permissions.flatMap(Object.values))
        .some((val) => !isNaN(val) && val === "1");

      if (!isAdminPermissionValid) {
        handleToast({
          data: { status: false, message: "At least one permission needed" },
        });
        return;
      }

      const mutationResult = isEdit
        ? await updateBroker({ id: data.id, ...values })
        : await addBroker(values);

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        if (!isNew) {
          refetchPermissions();
        }
        const tabIndex = isNew ? 1 : 0;
        getNewUser(
          {
            ...mutationResult.data.data,
            id: mutationResult.data.data.id,
          },
          tabIndex
        );
      }
    };

    handleUpdateOrAdd();
  };

  const getPermissions = (permission) => {
    setBroker((prev) => ({
      ...prev,
      permissions: { ...prev.permissions, value: permission, required: false },
    }));
  };

  return (
    <form>
      <div className="form-group">
        <label className="fw-bold">Name*</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Name"
          name="name"
          onChange={handleChange}
          value={broker.name.value}
        />
        <span style={{ color: "red" }}>{brokerError?.name}</span>
      </div>
      <div className="row">
        {!isEdit && (
          <div className="form-group col-md-6">
            <label className="fw-bold">Email*</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Email"
              name="email"
              onChange={handleChange}
              value={broker.email.value}
              autoComplete="off"
            />
            <span style={{ color: "red" }}>{brokerError?.email}</span>
          </div>
        )}
        <div className="form-group col-md-6">
          <label className="fw-bold">Phone Number*</label>
          {/* <input
            type="tel"
            className="form-control"
            placeholder="Enter Phone Number"
            name="mobile_number"
            onChange={handleChange}
            value={broker.mobile_number.value}
          /> */}
          <PhoneInput
            key={"phoneInput"}
            country={"us"}
            value={broker.mobile_number.value}
            onChange={(phone) => {
              setBroker((prev) => {
                return {
                  ...prev,
                  mobile_number: { ...prev["mobile_number"], value: phone },
                };
              });
              const validationError = ValidateInputFields(
                "mobile_number",
                phone,
                broker["mobile_number"].required
              );
              setBrokerError((prevError) => {
                return { ...prevError, mobile_number: validationError };
              });
            }}
            className=""
            placeholder="Email/Mobile"
            enableSearch={true}
            containerClass="baseclass"
            inputClass="somebaseclass"
            countryCodeEditable={false}
            name="mobile_number"
            inputProps={{
              autoFocus: true,
            }}
            // disabled={disableGetCode}
          />

          <span style={{ color: "red" }}>{brokerError?.mobile_number}</span>
        </div>
        {user?.role !== "Admin" && (
          <div className="form-group col-md-6">
            <label className="fw-bold">Select Admins*</label>
            <select
              className="form-select shadow-none  "
              id="planets-selector"
              name="admin"
              value={broker.admin?.value}
              onChange={handleChange}
              disabled={isEdit}
            >
              <option value="">Select admin</option>
              {selectorBody}
            </select>
            <span style={{ color: "red" }}>{brokerError?.admin}</span>
          </div>
        )}
        {!isEdit && (
          <div className="form-group col-md-6">
            <label className="fw-bold">Password* </label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter User Name"
              name="password"
              onChange={handleChange}
              value={broker.password.value}
              autoComplete="off"
            />
            <span style={{ color: "red" }}>{brokerError?.password}</span>
          </div>
        )}
        {isLoading && !isSuccess && userPermissions?.length > 0 ? (
          <TableLoader />
        ) : isEdit ? (
          <RoleTable
            userId={data?.id}
            getPermissions={getPermissions}
            assignFor={true}
            isEdit={isEdit}
            userAddType="broker"
            permissions={userPermissions}
          />
        ) : user?.role === "Global Admin" && broker.admin?.value ? (
          <RoleTable
            assignFor={true}
            userId={broker.admin?.value}
            getPermissions={getPermissions}
            isEdit={isEdit}
            userAddType="broker"
            permissions={userPermissions}
          />
        ) : user?.role === "Admin" ? (
          <RoleTable
            assignFor={true}
            userId={user.id}
            getPermissions={getPermissions}
            isEdit={isEdit}
            userAddType="broker"
            permissions={userPermissions}
          />
        ) : (
          ""
        )}
      </div>
      <Button
        className="btn btn1"
        variant={"primary"}
        isLoading={isEdit ? updatingBroker : addingBroker}
        onClick={handleSubmit}
      >
        {isEdit ? "Update" : "Submit"}
      </Button>
    </form>
  );
};

export default Profile;
