import React, { useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import LeverageTable from "./LeverageTable";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { handleToast } from "../../utils/handleToast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { useSelector } from "react-redux";
import {
  useAddLeverageMutation,
  useGetLeverageQuery,
  useLeverageStatusMutation,
} from "../../../redux/leverageApi";

const Leverage = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    data: records = [],
    isLoading,
    refetch,
  } = useGetLeverageQuery({ userId: user.id });
  const [addLeverage, { isLoading: addingLeverage }] = useAddLeverageMutation();

  const [statusLeverage, { isLoading: statusUpdating }] =
    useLeverageStatusMutation();

  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [isDisable, setIsDisable] = useState(false);
  const [disableData, setDisableData] = useState(null);

  const [disabledStatus, setDisabledStatus] = useState(0);
  const [show, setShow] = useState(false);
  const [leverage, setLeverage] = useState({
    amount: { value: "", required: true },
    type: { value: "", required: true },
  });
  const [leverageError, setLeverageError] = useState({
    amount: "",
    type: "",
  });

  const handleClose = () => {
    setLeverage({
      amount: { value: "", required: true },
      type: { value: "", required: true },
    });
    setLeverageError(null);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
    setIsDisable(false);
  };
  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    setLeverage({
      name: { value: data.name, required: true },
      type: { value: data.type, required: true },
    });
    handleShow();
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setIsDelete(true);
    handleShow();
  };

  const handleDisable = (data) => {
    setDisableData(data);
    setDisabledStatus(data.status);
    setIsDisable(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeverage((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      leverage[name].required
    );

    setLeverageError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleDisable = async () => {
      const { id, status } = disableData;
      const result = await statusLeverage({ id, status });

      handleToast(result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(leverage).map(([key, { value }]) => [key, value])
      );

      for (let key in leverage) {
        const validationError = ValidateInputFields(
          key,
          leverage[key].value,
          leverage[key].required
        );
        setLeverageError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      console.log(values, "vleus");

      const mutationResult = await addLeverage(values);

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    isDelete
      ? handleDelete()
      : isDisable
      ? handleDisable()
      : handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Leverage</h3>
            <div className=" ms-auto">
              <button
                disabled={addingLeverage}
                className="btn btn1"
                onClick={handleShow}
              >
                Add Leverage
              </button>
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <LeverageTable
                  data={records}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onDisable={handleDisable}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={
          isDelete
            ? "Delete Leverage"
            : isDisable && disabledStatus === "0"
            ? "Enable Leverage"
            : isDisable && disabledStatus === "1"
            ? "Disable Leverage"
            : isEdit
            ? "Edit Leverage"
            : "Add Leverage"
        }
        footer={
          <Button
            className="btn btn1"
            variant={isDelete ? "danger" : "primary"}
            isLoading={isDisable ? statusUpdating : addingLeverage}
            onClick={handleSubmit}
          >
            {isDelete
              ? "Delete"
              : isDisable && disabledStatus === "0"
              ? "Enable"
              : isDisable && disabledStatus === "1"
              ? "Disable"
              : isEdit
              ? "Update"
              : "Submit"}
          </Button>
        }
      >
        {isDelete ? (
          <div>Are you sure you want to delete?</div>
        ) : isDisable ? (
          <div>{`Are you sure you want to ${
            disabledStatus === "0" ? "enable" : "disable"
          }?`}</div>
        ) : (
          <form>
            <div className="form-group">
              <label className="fw-bold">Leverage Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Name"
                name="amount"
                onChange={handleChange}
                value={leverage.amount.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>{leverageError?.amount}</span>
            </div>
            <div className="form-group">
              <label className="fw-bold">Type</label>
              <select
                className="form-select shadow-none  "
                id="type"
                name="type"
                value={leverage.type?.value}
                onChange={handleChange}
                disabled={isEdit}
              >
                <option value="">Select Type</option>
                {user?.role === "Global Admin" && (
                  <option value="default">Default</option>
                )}
                <option value="new">New</option>
              </select>
              <span style={{ color: "red" }}>{leverageError?.type}</span>
            </div>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default Leverage;
