import { apiSlice } from "./api";
import { getFxPairs, updateFxCoin } from "../components/constant/Api";

export const commissionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFxPairs: builder.query({
      query: () => ({
        url: getFxPairs,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    updateFxCoin: builder.mutation({
      query: (post) => ({
        url: updateFxCoin,
        method: "POST",
        body: post,
      }),
    }),
  }),
});

export const { useGetFxPairsQuery, useUpdateFxCoinMutation } = commissionApi;
