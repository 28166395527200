import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, Tooltip, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";
import CopyToClipboard from "../../widgets/CopyToClipboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const UsersTable = ({
  data,
  onEdit,
  onStatus,
  onPermission,
  access,
  handleUserInfo,
  addBalance,
}) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Serial No.",
        enableEditing: false,
        size: 80,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        accessorKey: "user_name",
        header: "Username",
        Cell: ({ row, item }) => (
          <span>{row.original.user_name ? row.original.user_name : "-"}</span>
        ),
      },
      {
        accessorKey: "refer_id",
        header: "Refer Code",
        Cell: ({ row }) => (
          <span style={{ display: "flex", gap: "4px" }}>
            {row.original.refer_id ? row.original.refer_id : "-"}{" "}
            <CopyToClipboard text={row.original.refer_id} />
          </span>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        Cell: ({ row, item }) => (
          <span>{row.original.name ? row.original.name : "-"}</span>
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ row, item }) => (
          <span>{row.original.email ? row.original.email : "-"}</span>
        ),
      },
      {
        accessorKey: "mobile_number",
        header: "Phone",
        Cell: ({ row, item }) => (
          <span>
            {row.original.mobile_number ? row.original.mobile_number : "-"}
          </span>
        ),
      },
      {
        accessorKey: "createdAt",
        header: "Date",
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.createdAt)}</span>,
      },
      // ...(onPermission
      //   ? [
      //       {
      //         accessorKey: "actions",
      //         header: "Permissions",
      //         Cell: ({ row }) => (
      //           <Tooltip title="Add Permission">
      //             <button
      //               className="btn btn1"
      //               color="error"
      //               onClick={() => onPermission(row.original)}
      //             >
      //               <span>Add Permissions</span>
      //             </button>
      //           </Tooltip>
      //         ),
      //       },
      //     ]
      //   : []),
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: access?.read ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: access?.write,
    getRowId: (row) => row.id,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip
          title="Edit"
          disabled={row.original.status === "1" ? false : true}
        >
          <IconButton onClick={() => onEdit(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {addBalance && (
          <Tooltip
            title="Add Balance"
            disabled={row.original.status === "1" ? false : true}
          >
            <IconButton onClick={() => addBalance(row.original)}>
              <AttachMoneyIcon />
            </IconButton>
          </Tooltip>
        )}
        {handleUserInfo && (
          <Tooltip
            title="User Info"
            disabled={row.original.status === "1" ? false : true}
          >
            <IconButton onClick={() => handleUserInfo(row.original)}>
              <AccountBalanceIcon />
            </IconButton>
          </Tooltip>
        )}
        {access.delete && (
          <Tooltip title="Status">
            <IconButton
              color={row.original.status === "1" ? "success" : "error"}
              onClick={() => onStatus(row.original)}
            >
              {row.original.status === "1" ? (
                <ToggleOnIcon />
              ) : (
                <ToggleOffIcon />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
