import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useGetCryptoPairsQuery } from "../../../redux/cryptoPairsApi";
import TableLoader from "../../widgets/TableLoader";
import { useGetCryptosQuery } from "../../../redux/cryptoApi";
import { useAddBalanceMutation } from "../../../redux/usersApi";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
const defaultState = {
  amount: { value: "", required: true },
  currencyId: { value: "", required: true },
};

export const AddBalanceForm = ({ editData, onClose }) => {
  const { data: coins, isLoading } = useGetCryptosQuery();
  const [selectedPairs, setSelectedPairs] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [currantPairs, setCurrantPairs] = useState([]);

  const { data: records } = useGetCryptoPairsQuery();
  const [addBalance, { isLoading: addingBalance }] = useAddBalanceMutation();

  const [balance, setBalance] = useState(defaultState);
  const [balanceError, setBalanceError] = useState({
    amount: "",
    currencyId: "",
  });

  useEffect(() => {
    const createKeyValuePair = (coins) => {
      return coins?.map((lev) => ({
        value: lev?.id,
        label: `${lev.name} (${lev.symbol})`,
      }));
    };
    if (coins) {
      const pairValues = createKeyValuePair(coins);

      setCurrantPairs(pairValues);
      setIsShow(true);
    }
  }, [coins]);

  // const selectedOptions = useMemo(() => {
  //   return currantPairs?.map((pair) => ({
  //     value: pair.pair_id,
  //     label: `${pair.name1?.toUpperCase()}/${pair.name2?.toUpperCase()}`,
  //   }));
  // }, [currantPairs]);

  const handlePermissionsTabsSubmit = async () => {
    console.log(selectedPairs, editData, balance);

    const values = Object.fromEntries(
      Object.entries(balance).map(([key, { value }]) => [key, value])
    );

    for (let key in balance) {
      const validationError = ValidateInputFields(
        key,
        balance[key].value,
        balance[key].required
      );
      setBalanceError((prevError) => ({
        ...prevError,
        [key]: validationError,
      }));
      if (validationError) return;
    }

    console.log(values, "vleussss", editData);

    const result = await addBalance({ ...values, userId: editData.id });
    toast.dismiss();
    if (result?.data?.status) {
      toast.success(result.data.message);
      onClose();
      setBalance(defaultState);
    } else {
      toast.error(result?.data?.message || "Error occurred");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBalance((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      balance[name].required
    );

    setBalanceError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  if (!records) {
    return <TableLoader />;
  }
  return (
    <>
      {isLoading ? (
        <TableLoader />
      ) : (
        <div>
          {/* <h4>Select Leverages</h4> */}
          {!isShow ? (
            <TableLoader />
          ) : (
            <>
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="fw-bold">Name*</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Name"
                    name="amount"
                    onChange={handleChange}
                    value={balance.amount.value}
                  />
                  <span style={{ color: "red" }}>{balanceError?.amount}</span>
                </div>
                <div className="form-group col-md-6">
                  <label className="fw-bold">Name*</label>
                  <Select
                    // isMulti
                    name="colors"
                    options={currantPairs}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    onChange={(selected) => {
                      setSelectedPairs(selected);
                      setBalance((prev) => {
                        return {
                          ...prev,
                          currencyId: {
                            ...prev["currencyId"],
                            value: selected.value,
                          },
                        };
                      });
                      setBalanceError((prevError) => {
                        return {
                          ...prevError,
                          currencyId: "",
                        };
                      });
                    }}
                  />
                  <span style={{ color: "red" }}>
                    {balanceError?.currencyId}
                  </span>
                </div>
              </div>

              <Button
                className="btn btn1 mt-2"
                onClick={handlePermissionsTabsSubmit}
                isLoading={addingBalance}
              >
                Save
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};
