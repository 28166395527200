import React, { useState, useMemo } from "react";
import TableLoader from "../../widgets/TableLoader";
import {
  usePostBrokerCredentialsMutation,
  useGetBrokersQuery,
  useGetBrokerCredentialsQuery,
} from "../../../redux/brokerApi";
import AccountBrokerTable from "./AccountBrokerTable";
import BalanceTable from "./BalanceTable";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { useGetCryptosQuery } from "../../../redux/cryptoApi";
import { useSelector } from "react-redux";

const AccountBroker = () => {
  const {
    user: { role: userType },
  } = useSelector((state) => state.auth);

  const {
    data: brokerCredentials,
    isLoading,
    refetch,
  } = useGetBrokerCredentialsQuery();
  // const { data: records, isLoading, refetch } = useGetAccountsQuery();
  const [addBrokerCredentials, { isLoading: addingCredentials }] =
    usePostBrokerCredentialsMutation();
  // const [deleteAccount, { isLoading: deletingAccount }] =
  //   useDeleteAccountMutation();
  // const [updateAccount, { isLoading: updatingAccount }] =
  //   useUpdateAccountMutation();

  const { data: cryptoCoins } = useGetCryptosQuery();
  const { data: brokers } = useGetBrokersQuery();
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [show, setShow] = useState(false);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [account, setAccount] = useState({
    apiKey: { value: "", required: true },
    secretKey: { value: "", required: true },
  });
  const [accountError, setAccountError] = useState({
    apiKey: "",
    secretKey: "",
  });

  const handleClose = () => {
    setAccount({
      apiKey: { value: "", required: true },
      secretKey: { value: "", required: true },
      exchangeUrl: { value: "", required: true },
    });
    setAccountError(null);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
  };
  const [accountData, setAccountData] = useState(null);
  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    setAccount({
      name: { value: data.name, required: true },
      email: { value: data.email, required: true },
      mobile_number: { value: data.mobile_number, required: true },
      brokerId: {
        value: data.refred_by_id,
        required: userType === "Broker" ? false : true,
      },
      password: { value: "", required: false },
    });
    handleShow();
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setIsDelete(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccount((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      account[name].required
    );

    setAccountError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const selectorBody = useMemo(() => {
    return brokers?.map((cryptoCoin) => (
      <option value={cryptoCoin.id} key={cryptoCoin.id}>
        {cryptoCoin.name}
      </option>
    ));
  }, [brokers]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const addCredentials = async () => {
      const values = Object.fromEntries(
        Object.entries(account).map(([key, { value }]) => [key, value])
      );

      for (let key in account) {
        const validationError = ValidateInputFields(
          key,
          account[key].value,
          account[key].required
        );
        setAccountError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const mutationResult = await addBrokerCredentials(values);

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
      }
    };

    const handleToast = (result) => {
      toast.dismiss();
      if (result?.data?.status) {
        toast.success(result.data.message);
      } else {
        toast.error(result?.data?.message || "Error occurred");
      }
    };

    addCredentials();
  };

  const handleUserBalances = () => {
    setShow(true);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Add Account Information</h3>
            <div className=" ms-auto">
              {/* <button
                disabled={addingAccount}
                className="btn btn1"
                onClick={handleShow}
              >
                Add ACCOUNT
              </button> */}
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : brokerCredentials.length === 0 ? (
            <div className="white-box">
              <form className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold">Api Key</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter api key"
                      name="apiKey"
                      onChange={handleChange}
                      value={account.apiKey.value}
                    />
                    <span style={{ color: "red" }}>{accountError?.apiKey}</span>
                  </div>

                  <div className="form-group">
                    <label className="fw-bold">Secret Key</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter secret key"
                      name="secretKey"
                      onChange={handleChange}
                      value={account.secretKey.value}
                    />
                    <span style={{ color: "red" }}>
                      {accountError?.secretKey}
                    </span>
                  </div>

                  <Button
                    className="btn btn1"
                    variant={"primary"}
                    isLoading={addingCredentials}
                    onClick={handleSubmit}
                  >
                    {"Submit"}
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <AccountBrokerTable
                  data={brokerCredentials}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  handleUserBalances={handleUserBalances}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {brokerCredentials && brokerCredentials.length > 0 && (
        <CustomModal
          isOpen={show}
          onClose={handleClose}
          title={"User Balances"}
          maxWidth="800px"
          footer={
            <Button
              className="btn btn1"
              variant={"primary"}
              isLoading={false}
              onClick={handleSubmit}
            >
              {"Update"}
            </Button>
          }
        >
          <BalanceTable
            data={brokerCredentials}
            onEdit={handleEdit}
            onDelete={handleDelete}
            handleUserBalances={handleUserBalances}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default AccountBroker;
