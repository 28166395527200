import React, { useState, useMemo } from "react";
import {
  useGetCryptoPairsQuery,
  useAddCryptoPairsMutation,
  useUpdateCryptoPairsMutation,
  useDeleteCryptoPairsMutation,
} from "../../../redux/cryptoPairsApi";
import { useGetCryptosQuery } from "../../../redux/cryptoApi";
import TableLoader from "../../widgets/TableLoader";
import CryptoPairsTable from "./CryptoPairsTable";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { useGetExchangeCredentialsQuery } from "../../../redux/exchange";

const CryptoPairs = () => {
  const { data: cryptoCoins } = useGetCryptosQuery();
  const { data: records, isLoading, refetch } = useGetCryptoPairsQuery();
  const [addCryptoPair, { isLoading: addingCryptoPair }] =
    useAddCryptoPairsMutation();
  const [deleteCryptoPair, { isLoading: deletingCryptoPair }] =
    useDeleteCryptoPairsMutation();
  const [updateCryptoPair, { isLoading: updatingCryptoPair }] =
    useUpdateCryptoPairsMutation();
  const { data: exchangeData, isLoading: exchangeLoading } =
    useGetExchangeCredentialsQuery();
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [crypto, setCryptoPair] = useState({
    firstCoinId: { value: "", required: true },
    secondCoinId: { value: "", required: true },
    vendorId: { value: "", required: true },
    is_vendor: { value: "1", required: true },
  });
  const [cryptoPairError, setCryptoPairError] = useState({
    firstCoinId: "",
    secondCoinId: "",
    vendorId: "",
    is_vendor: "",
  });

  const selectorBody = useMemo(() => {
    return cryptoCoins?.map((cryptoCoin) => (
      <option value={cryptoCoin.id} key={cryptoCoin.id}>
        {cryptoCoin.name}
      </option>
    ));
  }, [cryptoCoins]);

  const selectorBodyVendors = useMemo(() => {
    return exchangeData?.exChangeInfo?.map((vendor) => (
      <option value={vendor.id} key={vendor.id}>
        {vendor.exchange}
      </option>
    ));
  }, [exchangeData]);

  const handleClose = () => {
    setCryptoPair({
      firstCoinId: { value: "", required: true },
      secondCoinId: { value: "", required: true },
      vendorId: { value: "", required: true },
      is_vendor: { value: "1", required: true },
    });
    // setCryptoPairError(null);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
  };

  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    setCryptoPair({
      firstCoinId: { value: data.coin_first_id, required: true },
      secondCoinId: { value: data.coin_second_id, required: true },
      vendorId: { value: data.vendor_id, required: true },
      is_vendor: { value: data.is_vendor, required: true },
    });
    handleShow();
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setIsDelete(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "is_vendor") {
      setCryptoPair((prev) => {
        return {
          ...prev,
          [name]: { ...prev[name], value: checked ? "1" : "0" },
        };
      });
    } else {
      setCryptoPair((prev) => {
        return { ...prev, [name]: { ...prev[name], value } };
      });
    }

    const validationError = ValidateInputFields(
      name,
      value,
      crypto[name].required
    );

    setCryptoPairError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleDelete = async () => {
      const { id, vendor_id } = deleteData;
      const result = await deleteCryptoPair({ id, vendor_id });

      handleToast(result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(crypto).map(([key, { value }]) => [key, value])
      );

      for (let key in crypto) {
        const validationError = ValidateInputFields(
          key,
          crypto[key].value,
          crypto[key].required
        );
        setCryptoPairError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const mutationResult = isEdit
        ? await updateCryptoPair({ id: editData.id, ...values })
        : await addCryptoPair(values);

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleToast = (result) => {
      toast.dismiss();
      if (result?.data?.status) {
        toast.success(result.data.message);
      } else {
        toast.error(result?.data?.message || "Error occurred");
      }
    };

    isDelete ? handleDelete() : handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Pairs</h3>
            <div className=" ms-auto">
              <button
                disabled={addingCryptoPair}
                className="btn btn1"
                onClick={handleShow}
              >
                Add Pair
              </button>
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <CryptoPairsTable
                  data={records}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={
          isDelete
            ? "Delete Crypto Pair"
            : isEdit
            ? "Edit Crypto Pair"
            : "Add Crypto Pair"
        }
        footer={
          <Button
            className="btn btn1"
            variant={isDelete ? "danger" : "primary"}
            isLoading={
              isDelete
                ? deletingCryptoPair
                : isEdit
                ? updatingCryptoPair
                : addingCryptoPair
            }
            onClick={handleSubmit}
          >
            {isDelete ? "Delete" : isEdit ? "Update" : "Submit"}
          </Button>
        }
      >
        {isDelete ? (
          <div>Are you sure you want to delete?</div>
        ) : (
          <form>
            <div className="form-group">
              <label className="fw-bold">Select First Coin*</label>
              <select
                className="form-select shadow-none  "
                id="planets-selector"
                name="firstCoinId"
                value={crypto.firstCoinId?.value}
                onChange={handleChange}
                disabled={isEdit}
              >
                <option value="">Select Coin</option>
                {selectorBody?.filter(
                  (option) => option.key !== `${crypto.secondCoinId?.value}`
                )}
              </select>
              <span style={{ color: "red" }}>
                {cryptoPairError?.firstCoinId}
              </span>
            </div>
            <div className="form-group">
              <label className="fw-bold">Select Second Coin*</label>
              <select
                disabled={isEdit}
                className="form-select shadow-none  "
                id="planets-selector"
                name="secondCoinId"
                value={crypto.secondCoinId?.value}
                onChange={handleChange}
              >
                <option value="">Select Coin</option>
                {selectorBody?.filter(
                  (option) => option.key !== `${crypto.firstCoinId?.value}`
                )}
              </select>
              <span style={{ color: "red" }}>
                {cryptoPairError?.secondCoinId}
              </span>
            </div>
            <div className="form-group">
              <label className="fw-bold">Select Vendor*</label>
              <select
                className="form-select shadow-none  "
                name="vendorId"
                value={crypto.vendorId?.value}
                onChange={handleChange}
              >
                <option value="">Select Vendor</option>
                {selectorBodyVendors}
              </select>
              <span style={{ color: "red" }}>{cryptoPairError?.vendorId}</span>
            </div>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  id={`is_vendor`}
                  name={`is_vendor`}
                  value={crypto.is_vendor?.value}
                  checked={crypto.is_vendor?.value === "1"}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor={`is_vendor`} className="form-check-label">
                  is Vendor
                </label>
              </div>
            </div>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default CryptoPairs;
