import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const BuyOrdersTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "S. No.",
        enableEditing: false,
        size: 40,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        accessorKey: "user_name",
        header: "Username",
        size: 50,
      },
      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ row: { original } }) => <span>{original.email || "-"}</span>,
      },
      {
        accessorKey: "mobile_number",
        header: "Mobile No.",
        Cell: ({ row: { original } }) => (
          <span>{original.mobile_number || "-"}</span>
        ),
      },
      {
        accessorKey: "per_price",
        header: "Buy Price",
      },
      {
        accessorKey: "total_buy_get_amount",
        header: "Quantity",
      },
      {
        // accessorKey: "refer_id",
        header: "Total(AMT)",
        Cell: ({ row }) => (
          <span>
            {Number(row.original.total_buy_get_amount) *
              Number(row.original.per_price)}{" "}
          </span>
        ),
      },

      {
        accessorKey: "buy_fees",
        header: "Buy Fees",
      },
      {
        accessorKey: "first_coin_symbol",
        header: "Pair",
        Cell: ({ row: { original } }) => (
          <span>{`${original.first_coin_symbol}/${original.second_coin_symbol}`}</span>
        ),
      },
      {
        accessorKey: "orderMethod",
        header: "Order Type",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row: { original } }) => <span>{original.status}</span>,
      },

      {
        accessorKey: "createdAt",
        header: "Date",
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.update_at)}</span>,
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
