import React, { useState } from "react";
import Button from "./Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import CopyToClipboard from "./CopyToClipboard";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import { apiSlice } from "../../redux/api";
import LockIcon from "@material-ui/icons/Lock";
import ShareIcon from "@material-ui/icons/Share";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import {
  useGetNotificationsQuery,
  usePostNotificationsMutation,
} from "../../redux/notificationsApi";
import Drawer from "@mui/material/Drawer";
import NotificationsDrawer from "./NotificationsDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { useDropdown } from "./useDropdown";
import { useEffect } from "react";

function Header({ changeChartTheme }) {
  const dispatch = useDispatch();
  const themeDropdown = useDropdown();
  const [selectedTheam, setSelectedTheam] = useState("");
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { data, refetch } = useGetNotificationsQuery();
  const [readNotifications] = usePostNotificationsMutation();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => async () => {
    console.log("toggle");
    setOpen(newOpen);
    await handleReadNotifications();
  };

  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logout());
    handleClose();
    navigate("/", { replace: true });
  };

  const handleReadNotifications = async () => {
    await readNotifications().unwrap();
    refetch();
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
      setSelectedTheam(theme);
      if (changeChartTheme) {
        changeChartTheme(theme);
      }
    }
  }, []);

  const notificationsItems = () => {
    if (!data || !data.notifications) {
      return null;
    }

    const displayedNotifications = data.notifications.slice(0, 5);

    return (
      <>
        <List sx={{ width: "100%", maxWidth: 360 }}>
          {displayedNotifications.map((item, index) => (
            <>
              <ListItem key={index} alignItems="flex-start">
                {item.message} {item.name}
              </ListItem>
              <Divider component="li" />
            </>
          ))}
        </List>
        {data.notifications.length > 10 && (
          <Link className="mt-4 d-flex px-4" to="/notifications">
            View All
          </Link>
        )}
      </>
    );
  };

  const setTheme = (e, themeName) => {
    e.preventDefault();
    if (themeName === "dimTheme") {
      document.body.className = "dimTheme";
    } else {
      document.body.className = themeName;
    }
    setSelectedTheam(themeName);
    localStorage.setItem("theme", themeName);
    if (changeChartTheme) {
      changeChartTheme(themeName);
    }
  };

  return (
    <div>
      <header className="topbar" data-navbarbg="skin5">
        <nav className="navbar top-navbar navbar-expand-md navbar-dark">
          <div className="navbar-header" data-logobg="skin6">
            <Link className="navbar-brand" to="/dashboard">
              <img
                src="/assets/img/logo.png"
                alt="logo"
                className="img-fluid"
              />
            </Link>
          </div>
          <div
            className="navbar-collapse collapse"
            id="navbarSupportedContent"
            data-navbarbg="skin5"
          >
            <ul className="navbar-nav ms-auto d-flex align-items-center">
              {user?.role === "Global Admin" && (
                <Dropdown
                  className="d-inline mx-2 bell"
                  autoClose="outside"
                  // show={isDropdownOpen}
                  // onClick={handleReadNotifications}
                  onClick={toggleDrawer(true)}
                >
                  <Dropdown.Toggle
                    id="dropdown-autoclose-outside"
                    // onClick={handleToggle}
                  >
                    <NotificationsRoundedIcon />
                    {data && data?.unread > 0 && (
                      <span className="count-notifications">
                        {data?.unread}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  {/* <Dropdown.Menu>{notificationsItems()}</Dropdown.Menu> */}
                </Dropdown>
              )}

              <li>
                <Dropdown className="d-inline mx-2" autoClose="outside">
                  <Dropdown.Toggle id="dropdown-autoclose-outside">
                    <div className="profile-pic">
                      <img
                        src="/assets/img/users/varun.jpg"
                        alt="user-img"
                        width={36}
                        className="img-circle"
                      />
                      <span className=" font-medium">{user?.name}</span>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="p-2">
                      <div style={{ display: "flex", gap: "2px" }}>
                        <span>
                          <ShareIcon fontSize="small" /> Refer ID:{" "}
                          {user?.refer_id}{" "}
                        </span>
                        <CopyToClipboard text={user?.refer_id} />
                      </div>{" "}
                    </div>
                    <div className="p-2">
                      <AccountCircleIcon fontSize="small" /> Username:{" "}
                      {user?.user_name}
                    </div>
                    {user?.role === "Broker" ? (
                      <div className="p-2">
                        <Link to="/change-password">
                          <LockIcon fontSize="small" /> Change Password
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <div className="dropdown user_dropdown  me-3">
              <span
                type="button"
                className="dropdown-toggle color-toggle"
                data-toggle="dropdown"
                onClick={() => themeDropdown.setIsOpen(!themeDropdown.isOpen)}
              >
                {selectedTheam === "lightTheme" ? (
                  <i className="fa fa-sun-o"></i>
                ) : selectedTheam === "dimTheme" ? (
                  <i className="fa fa-moon-o"></i>
                ) : (
                  <i className="fa fa-moon-o"></i>
                )}{" "}
              </span>
              <div
                ref={themeDropdown.ref}
                className={
                  themeDropdown.isOpen
                    ? "dropdown-menu2 active"
                    : "dropdown-menu2"
                }
              >
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "lightTheme")}
                >
                  <i className="fa fa-sun-o mr-2"></i> Light
                </a>
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "dimTheme")}
                >
                  <i className="fa fa-star-o mr-1"></i> Dim
                </a>
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "darkTheme")}
                >
                  <i className="fa fa-moon-o mr-1"></i> Dark
                </a>
              </div>
            </div>
            <Button className="me-3" variant="danger" onClick={handleShow}>
              Logout
            </Button>
          </div>
        </nav>
      </header>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button className="btn me-2" variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {/* {notificationsItems()} */}
        <NotificationsDrawer data={notificationsItems()} />
      </Drawer>
    </div>
  );
}
export default Header;
