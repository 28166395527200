import React from "react";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { handleToast } from "../../utils/handleToast";
import { useUserStatusMutation } from "../../../redux/usersApi";

export const UserStatus = ({ id, onClose, show, refetch, status }) => {
  const [userStatus, { isLoading }] = useUserStatusMutation();
  const handleStatus = async () => {
    const statusToUpdate = status === "1" ? "0" : "1";
    const result = await userStatus({ id, status: statusToUpdate });

    handleToast(result);
    if (result?.data?.status) {
      refetch();
      onClose();
    }
  };
  return (
    <CustomModal
      isOpen={show}
      onClose={onClose}
      title={"Update Status"}
      footer={
        <Button
          className="btn btn1"
          variant={"danger"}
          isLoading={isLoading}
          onClick={handleStatus}
        >
          Update Status
        </Button>
      }
    >
      <div>Are you sure you want to update Status?</div>
    </CustomModal>
  );
};
