import React, { useRef, useEffect, useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import PositionSummeryTable from "./PositionSummeryTable";
import { socketUrl } from "../../constant/BaseUrl";
import socketIOClient from "socket.io-client";

const PositionSummery = () => {
  const localSocket = useRef(null);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!localSocket.current) {
      localSocket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });
      localSocket.current.on("connect", () => {
        console.log("Socket is connected");
        handleSocketMessages(localSocket);
      });
      localSocket.current.emit("ping");
      localSocket.current.emit("getAdminPositionSummerFx");
    }
    return () => {
      // if (localSocket.current) {
      //   console.log("WebSocket connection will be closed on unmount.");
      //   localSocket.current.disconnect();
      // }
    };
  }, []);

  const handleSocketMessages = (localSocket) => {
    localSocket.current.on("pong", () => {
      console.log("Received PONG from server");
    });

    localSocket.current.on("resultAdminPositionSummeryFx", (data) => {
      console.log(data, "buyOrderData, sellOrderData ");
      setOrders(data);
    });

    localSocket.current.off("refresh");
    localSocket.current.on("refresh", () => {
      localSocket.current.emit("ping");
      localSocket.current.emit("getAdminPositionSummerFx");
      handleSocketMessages(localSocket);
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Position Summery</h3>
            <div className=" ms-auto"></div>
          </div>

          {orders.length === 0 ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <PositionSummeryTable data={orders} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PositionSummery;
