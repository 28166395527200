import React, { useState, useMemo, useEffect } from "react";
import {
  useGetCommissionQuery,
  useAddCommissionMutation,
  useUpdateCommissionMutation,
  useDeleteCommissionMutation,
  useGetPairsCommissionQuery,
} from "../../../redux/commissionApi";
import { useGetCryptoPairsQuery } from "../../../redux/cryptoPairsApi";
import TableLoader from "../../widgets/TableLoader";
import TradeFeeTable from "./TradeFeeTable";
import {
  ValidateInputFields,
  isNegative,
} from "../../validations/ValidateInputFields";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useGetAssetPairsQuery } from "../../../redux/usersApi";

const TradeFee = () => {
  const { user } = useSelector((state) => state.auth);
  const [remainPairs, setRemainPairs] = useState(null);
  const {
    data: pairsData,
    isLoading: pairsLoading,
    refetch: refetchPairs,
  } = useGetAssetPairsQuery({ userId: user?.id, type: "crypto" });

  const {
    data: commissions = [],
    isLoading,
    refetch,
    isFetching,
  } = useGetCommissionQuery({
    userId: user?.id,
  });

  // const { data: cryptoPairs } = useGetCryptoPairsQuery();
  const { data: cryptoPairs } = useGetPairsCommissionQuery({
    userId: user?.id,
  });

  const [addCommission, { isLoading: addingCommission }] =
    useAddCommissionMutation();
  const [deleteCommission, { isLoading: deletingCommission }] =
    useDeleteCommissionMutation();
  const [updateCommission, { isLoading: updatingCommission }] =
    useUpdateCommissionMutation();
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [selectedPairs, setSelectedPairs] = useState([]);
  const [commission, setCommission] = useState({
    pairId: { value: "", required: true },
    type: { value: "", required: true },
    buyCommission: { value: "", required: true },
    sellCommission: { value: "", required: true },
  });
  const [commissionError, setCommissionError] = useState({
    pairId: "",
    type: "",
    buyCommission: "",
    sellCommission: "",
  });

  useEffect(() => {
    if (pairsData && commissions && cryptoPairs) {
      if (user.role === "Global Admin") {
        const cryPairs = cryptoPairs.map((item) => {
          return {
            pair_id: item.id,
            name1: item.first_coin_symbol,
            name2: item.second_coin_symbol,
          };
        });
        setRemainPairs(cryPairs);
      } else {
        let result = pairsData.filter(
          (obj1) => !commissions.some((obj2) => obj2.pairId === obj1.pair_id)
        );
        setRemainPairs(result);
      }
    }
  }, [pairsData, commissions, cryptoPairs, user]);

  const handleClose = () => {
    setCommission({
      pairId: { value: "", required: true },
      type: { value: "", required: true },
      buyCommission: { value: "", required: true },
      sellCommission: { value: "", required: true },
    });
    setCommissionError(null);
    setSelectedPairs([null]);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
  };

  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);

    setIsEdit(true);
    setSelectedPairs({
      value: data.pairId,
      label: `${data.first_coin_symbol}/${data.second_coin_symbol}`,
    });
    setCommission({
      pairId: { value: data.pairId, required: true },
      buyCommission: { value: data.buy_commission, required: true },
      sellCommission: { value: data.sell_commission, required: true },
      type: { value: data.type, required: true },
    });
    handleShow();
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setIsDelete(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCommission((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    let validationError = ValidateInputFields(
      name,
      value,
      commission[name].required
    );

    if (validationError) {
      setCommissionError((prevError) => {
        return { ...prevError, [name]: validationError };
      });
      return;
    }
    validationError = isNegative(value);
    setCommissionError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSelectChange = (selected) => {
    const { type } = cryptoPairs.find((pair) => pair.id === selected.value);

    setSelectedPairs(selected);
    setCommission((prev) => ({
      ...prev,
      pairId: { value: selected.value, required: true },
      type: { value: type, required: true },
    }));
    const validationError = ValidateInputFields(
      "pairId",
      selected.value,
      commission["pairId"].required
    );

    setCommissionError((prevError) => {
      return { ...prevError, pairId: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleDelete = async () => {
      const { id } = deleteData;
      const result = await deleteCommission({ id });

      handleToast(result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(commission).map(([key, { value }]) => [key, value])
      );

      for (let key in commission) {
        let validationError = ValidateInputFields(
          key,
          commission[key].value,
          commission[key].required
        );
        setCommissionError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
        validationError = isNegative(commission[key].value);
        if (validationError) return;
      }

      // return;

      const mutationResult = isEdit
        ? await updateCommission({ id: editData.id, ...values })
        : await addCommission(values);

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleToast = (result) => {
      toast.dismiss();
      if (result?.data?.status) {
        toast.success(result.data.message);
      } else {
        toast.error(result?.data?.message || "Error occurred");
      }
    };

    isDelete ? handleDelete() : handleUpdateOrAdd();
  };
  const options = useMemo(() => {
    return (
      remainPairs &&
      remainPairs?.map((pair) => ({
        value: pair.pair_id,
        label: `${pair.name1.toUpperCase()}/${pair.name2.toUpperCase()}`,
      }))
    );
  }, [remainPairs]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Trade Fees</h3>
            <div className=" ms-auto">
              <button
                disabled={addingCommission || isFetching}
                className="btn btn1"
                onClick={handleShow}
              >
                Add Trade Fees
              </button>
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div
                className="table-responsive"
                disabled={isFetching ? true : false}
              >
                <TradeFeeTable
                  data={commissions}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={
          isDelete
            ? "Delete Trade Fees"
            : isEdit
            ? "Edit Trade Fees"
            : "Add Trade Fees"
        }
        footer={
          <Button
            className="btn btn1"
            variant={isDelete ? "danger" : "primary"}
            isLoading={
              isDelete
                ? deletingCommission
                : isEdit
                ? updatingCommission
                : addingCommission
            }
            onClick={handleSubmit}
          >
            {isDelete ? "Delete" : isEdit ? "Update" : "Submit"}
          </Button>
        }
      >
        {isDelete ? (
          <div>Are you sure you want to delete?</div>
        ) : (
          <form>
            <div className="form-group">
              <label className="fw-bold">Pair Name*</label>
              {isEdit ? (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter buy commission"
                  name="buyCommission"
                  //   onChange={handleChange}
                  value={selectedPairs.label}
                  disabled
                />
              ) : (
                <Select
                  defaultValue={selectedPairs}
                  name="pairId"
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  onChange={handleSelectChange}
                />
              )}
              <span style={{ color: "red" }}>{commissionError?.pairId}</span>
            </div>
            <div className="form-group">
              <label className="fw-bold">Select Rate Type*</label>
              <select
                className="form-select shadow-none  "
                id="planets-selector"
                name="type"
                value={commission.type?.value}
                onChange={handleChange}
                disabled={isEdit || user.role !== "Global Admin"}
              >
                <option>Select Type</option>
                <option value="Percentage">Percentage</option>
                <option value="Flat">Flat</option>
              </select>
              <span style={{ color: "red" }}>{commissionError?.type}</span>
            </div>
            <div className="form-group">
              <label className="fw-bold">Buy Commission*</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter buy commission"
                name="buyCommission"
                onChange={handleChange}
                value={commission.buyCommission.value}
              />
              <span style={{ color: "red" }}>
                {commissionError?.buyCommission}
              </span>
            </div>
            <div className="form-group">
              <label className="fw-bold">Sell Commission*</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter sell commission"
                name="sellCommission"
                onChange={handleChange}
                value={commission.sellCommission.value}
              />
              <span style={{ color: "red" }}>
                {commissionError?.sellCommission}
              </span>
            </div>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default TradeFee;
