import {
  validateEmail,
  validatePhoneNumber,
  validateRequired,
  withFirstSpace,
  checkPassword,
  checkMinLength,
  validateAlphanumeric,
} from "./validationsFn";

export const ValidateInputFields = (name, value, required) => {
  let requiredError = "";
  requiredError = withFirstSpace(value ? value : "");
  if (requiredError) {
    return requiredError;
  }

  if (!required) {
    return null;
  }
  requiredError = validateRequired(value);
  if (requiredError) {
    return requiredError;
  }

  if (name === "name" || name === "symbol") {
    if (validateAlphanumeric(value)) {
      return validateAlphanumeric(value);
    }
    return checkMinLength(value, 3);
  }

  if (name === "email") {
    return validateEmail(value);
  }
  if (name === "phone" || name === "mobile_number" || name === "mobileNumber") {
    return validatePhoneNumber(value);
  }
  if (name === "password" || name === "newPassword") {
    return checkPassword(value);
  }
};

export function isNegative(value) {
  if (value === "") return;
  value = Number(value);
  if (value <= 0) {
    return "Value must be greater than 0";
  }
  return;
}
